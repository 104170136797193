import ServiceCommon from "./ServiceCommon";

class HeartRailsService extends ServiceCommon {
    /**
     * 都道府県取得
     */
    async getPrefectures() {
        const param = {
            method: "getPrefectures"
        };
        return await this.callHeartRailsAPI(param);
    }

    /**
     * 路線取得
     * @param {any} prefecture
     */
    async getLines(prefecture) {
        const param = {
            method: "getLines",
            prefecture
        };
        return await this.callHeartRailsAPI(param);
    }

    /**
     * 駅取得
     * @param {any} line
     * @param {any} name
     * @param {any} prefecture
     * @param {any} x
     * @param {any} y
     */
    async getStations(line, name, prefecture, x, y) {
        const param = {
            method: "getStations",
            line,
            name,
            prefecture,
            x,
            y
        };
        return await this.callHeartRailsAPI(param);
    }

    async callHeartRailsAPI(param) {
        const response = await this.get("//express.heartrails.com/api/json", param);
        if (response.status === 200) {
            return await response.json();
        }
        return null;
    }
}

const heartRailsService = new HeartRailsService();
export default heartRailsService;
