import React from "react";
import { Tooltip } from "@material-ui/core";
import { Help } from "@material-ui/icons";

export const HelpMessage = {
    PROJECT_OVERVIEW: (<span>
        ユーザー業種/システム内容/規模感/開発目的などを記載します<br />
        例）生命保険会社向け保険料シミュレーションシステムの新規開発。APIを活用し営業職員がタブレット端末で保険料を算出できるようにする。
    </span>),
    WORK_CONTENT: (<span>
        自分が担当した作業の詳細を記載します。作業の羅列だけでなくアピールポイントも含めたい。<br />
        例）新規5画面とバッチの開発。短納期ではあったが、予定通り納品。バグが多いシステムだったため、テスト設計に注力し品質改善を主導した。
    </span>),
    WHOLE_MEMBER_COUNT: "ユーザー社員、インフラ担当なども含めたPJ全体の人数。大まかな人数で問題ありません。どうしてもわからない覚えていない場合は空欄でも登録できます。",
    TEAM_MEMBER_COUNT: "サブシステムや機能単位の自分が所属するチーム人数です。必須入力です。",
    DEVELOPMENT_METHOD: "基本はウォーターフォールが多いと思います。スクラムマスターがいない「アジャイル風」は「アジャイル」でよいです。どうしても判断がつかない場合は空欄でも登録可能です。",
    PROJECT_ROLE: (<span>
        PM・・・プロジェクト全体の管理<br />
        PL・・・サブシステム又はチーム単位の管理<br />
        サブリーダー・・・数名の進捗管理<br />
        SE・・・基本設計から担当<br />
        PG・・・詳細設計から担当、実装のみ、テストのみもPGに該当<br />
        ※アジャイル的な現場では設計書がない場合もありまずが、設計相当の動きをしていればSEでよいと考えます。
    </span>),
    WORK_TYPE: "基本的には「新規開発」があったほうが評価が高い傾向です。複数種別経験している場合は、新規開発の方に寄せて記載してください。フィットする項目がない場合はマスタ追加検討しますのでお問い合わせください。",
    WORK_PROCESS: "修正程度でも経験している工程はなるべくチェックしてください。既存スキルシートの「調査分析」「環境構築」といった項目は「その他」にまとめました。詳細内容は「作業詳細」にテキストで記載してください。",
    TECH_USED: "「一覧から選択」「プルダウンから選択」「テキスト検索で選択」いずれの方法でもチェック可能です。バージョンやMW類・ツール類までなるべくすべて細かく網羅して記載してください。(Excel,Word,PowerPointは基本的には記載不要です。Office系の使用頻度が高いPMOやプリセールス職、テスト現場にてExcelのみ使用していたケースなどは記載してください。)　項目がない場合はマスタ追加検討しますので、営業までお問合せください。"
}

/**
 * ヘルプ用ツールチップ
 * 
 * @param {any} props
 */
export function HelpTooltip(props) {
    return (<Tooltip className="help" style={props.style} title={<span style={{ fontSize: '12px' }}>{props.message}</span>} placement="bottom-start">
        <Help />
    </Tooltip>);
}
