import React, { Component } from 'react';
import { InfoDocument } from './Parts/InfoDocument';

export class Dashboard extends Component {
    render() {
        return (
            <div>
                <InfoDocument />
            </div>
        );
    }
}
