import React, { Fragment } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css'
import SaveFab from '../../Common/SaveFab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        '& .id-column': {
            textAlign: 'center',
            backgroundColor: '#ffffc1'
        },
        '& .name-column': {

        },
        '& .version-column': {

        }
    },
});

export default function MasterTable(props) {
    const classes = useStyles();
    const hotTableComponent = React.createRef();
    const data = props.data;

    return (
        <Fragment>
            <HotTable
                ref={hotTableComponent}
                className={classes.table}
                data={data}
                rowHeaders={true}
                colHeaders={props.colHeaders}
                fillHandle={false}
                manualRowMove={true}
                contextMenu={props.contextMenu !== undefined ? props.contextMenu : {
                    items: {
                        'row_above': { name: '上に1行挿入' },
                        'row_below': { name: '下に1行挿入' },
                        "hsep2": "---------",
                        'undo': { name: '元に戻す' }
                    }
                }}
                columns={props.columns !== undefined ? props.columns : [
                    /// ID項目
                    {
                        className: 'id-column',
                        readOnly: true,
                        width: "50",
                        data: props.columnIdData
                    },
                    /// 名称項目
                    {
                        className: 'name-column',
                        readOnly: false,
                        width: "300",
                        data: props.columnNameData
                    }
                ]}
            />
            <SaveFab onClick={() => {
                const hotInstance = hotTableComponent.current.hotInstance;
                const dataList = hotInstance.getData();
                const result = dataList.map((data, i) => {
                    const id = data[0] ? data[0] : 0;
                    const name = data[1];
                    return {
                        id,
                        name,
                        sortOrder: i + 1,
                        isRegister: !id
                    };
                });
                props.onClick(result);
            }} />
        </Fragment>
    );
}
