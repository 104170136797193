import React, { Fragment } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Switch, IconButton, Dialog, DialogTitle, TextField, DialogActions, Button, DialogContent } from "@material-ui/core";
import { Container, Draggable } from 'react-smooth-dnd';
import SaveFab from '../../Common/SaveFab';
import { AddBox, DragHandle, Edit } from '@material-ui/icons';
import arrayMove from 'array-move';
import utility from '../../../Utility';
import userMaintenanceService from '../../../Service/UserMaintenanceService';
import { YesNoDialog } from '../../Common/Dialogs';



const createIndex = utility.createIndex();

export default function AppRole(props) {
    const [state, setState] = React.useState({
        operationContentList: props.data.appRoleMaster.operationContentList.slice(),
        appRoleList: props.data.appRoleMaster.appRoleList.slice(),
        roleOperationList: props.data.appRoleMaster.roleOperationList.slice()
    });
    const [dialog, setDialog] = React.useState({
        open: false,
        appRoleId: 0,
        appRoleName: ''
    });
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);


    /**
     * ドロップ時イベント
     * @param {any} removedIndex
     * @param {any} addedIndex
     */
    const onDrop = (removedIndex, addedIndex) => {
        const appRoleListCopy = state.appRoleList.slice();
        const appRoleListAfterSort = arrayMove(appRoleListCopy, removedIndex, addedIndex).map((value, index) => (
            {
                appRoleId: value.appRoleId,
                appRoleName: value.appRoleName,
                isRegister: value.isRegister,
                sortOrder: index + 1
            }
        ));
        setState(prevState => ({ ...prevState, appRoleList: appRoleListAfterSort }));
    };

    /**
     * Switch変更時イベント
     * @param {any} checked
     * @param {any} appRoleId
     * @param {any} operationContentId
     */
    const handleSwitchChange = (checked, appRoleId, operationContentId, isRegister) => {
        if (checked) {
            //checkした場合roleOperationListに追加する
            const newRoleOperationList = state.roleOperationList.slice();
            const roleOperation = { appRoleId, operationContentId, isRegister };
            newRoleOperationList.push(roleOperation);
            setState(prevState => ({ ...prevState, roleOperationList: newRoleOperationList }));
        } else {
            //checkを外した場合roleOperationListから削除する
            const newRoleOperationList = state.roleOperationList.filter(x => !(x.appRoleId === appRoleId && x.isRegister === isRegister && x.operationContentId === operationContentId));
            setState(prevState => ({ ...prevState, roleOperationList: newRoleOperationList }));
        }
    };

    /**
     * 追加ボタン押下時
     * */
    const handleAddButton = () => {
        setDialog(prevState => ({ ...prevState, open: true }));
    }

    /**
     * ダイヤログの変更、追加、キャンセルボタンを押したときの共通処理
     * @param {any} appRoleId
     * @param {any} appRoleName
     */
    const handleDialogButton = (appRoleId, appRoleName) => {
        if (appRoleName && state.appRoleList.filter(x => x.appRoleName === appRoleName).length === 0) {
        //権限名を入力した場合かつ既に登録されてない権限名だった場合
            if (appRoleId) {
                //更新
                const newAppRoleList = state.appRoleList.slice();
                const updateAppRole = newAppRoleList.filter(x => x.appRoleId === appRoleId)[0];
                const updateIndex = newAppRoleList.indexOf(updateAppRole);
                newAppRoleList[updateIndex].appRoleName = appRoleName;
                setState(prevState => ({ ...prevState, appRoleList: newAppRoleList }));
            } else {
                //登録
                const newAppRoleList = state.appRoleList.slice();
                newAppRoleList.push({
                    appRoleName,
                    appRoleId: createIndex.nextIndex(),
                    sortOrder: newAppRoleList.length + 1,
                    isRegister: true
                });
                setState(prevState => ({ ...prevState, appRoleList: newAppRoleList }));
            }
        }
        setDialog({
            open: false,
            appRoleId: 0,
            appRoleName: ''
        });
    };

    /**
     * 編集ボタン押下時
     * @param {any} appRoleId
     * @param {any} appRoleName
     */
    const handleEditButton = (appRoleId, appRoleName) => {
        setDialog(({
            open: true,
            appRoleId,
            appRoleName
        }));
    }

    return (<Fragment>
        <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '50px' }} >
                            <IconButton onClick={() => handleAddButton()}><AddBox color="primary" /></IconButton>
                        </TableCell>
                        <TableCell style={{ width: '200px' }}>権限名</TableCell>
                        {state.operationContentList.map(data => {
                            return (
                                <TableCell key={data.operationContentId} style={{ width: '80px' }}>
                                    <span>
                                        {data.operationContentName}
                                    </span>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <Container
                    dragHandleSelector=".column-drag-handle"
                    onDrop={({ removedIndex, addedIndex }) => onDrop(removedIndex, addedIndex)}
                    render={(ref) => {
                        return (
                            <TableBody ref={ref}>
                                {state.appRoleList.map((ar, arIndex) => {
                                    return (
                                        <Draggable key={arIndex} render={() => {
                                            return (
                                                <TableRow style={{ display: "table-row" }}>
                                                    <TableCell className="column-drag-handle"><DragHandle /></TableCell>
                                                    <TableCell>
                                                        <span style={{ display: "flex" }}>
                                                            <span>{ar.appRoleName}</span>
                                                            <IconButton size="small" onClick={() => handleEditButton(ar.appRoleId, ar.appRoleName)}><Edit fontSize="small" color="primary" /></IconButton>
                                                        </span>
                                                    </TableCell>
                                                    {state.operationContentList.map((oc, ocIndex) => {
                                                        const checked = state.roleOperationList.filter(x => x.appRoleId === ar.appRoleId && x.isRegister === ar.isRegister && x.operationContentId === oc.operationContentId).length === 1;
                                                        return (
                                                            <TableCell key={ocIndex}>
                                                                <Switch
                                                                    checked={checked}
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                    onChange={(e) => handleSwitchChange(e.target.checked, ar.appRoleId, oc.operationContentId, ar.isRegister)}
                                                                />
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        }} />
                                    );
                                })}
                            </TableBody>
                        );
                    }}>
                </Container>
            </Table>
        </TableContainer >
        <SaveFab
            onClick={() => setOpenConfirmDialog(true)}
        />
        <YesNoDialog
            open={openConfirmDialog}
            message="保存しますか？"
            onClickYes={() => {
                saveAppRole(state).then(data => {
                    setState({
                        operationContentList: data.operationContentList,
                        appRoleList: data.appRoleList,
                        roleOperationList: data.roleOperationList
                    });
                    setOpenConfirmDialog(false);
                });
            }}
            onClickNo={() => {
                setOpenConfirmDialog(false);
            }}
        />
        <AddAppRoleDialog open={dialog.open} onClick={(appRoleName) => handleDialogButton(dialog.appRoleId, appRoleName)} appRoleName={dialog.appRoleName} />
    </Fragment>);
}

function AddAppRoleDialog(props) {
    const [appRoleName, setAppRoleName] = React.useState("");
    const modeName = props.appRoleName ? '変更' : '追加';
    return (
        <Dialog open={props.open} onEnter={() => { setAppRoleName(props.appRoleName); }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">権限{modeName}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="権限名"
                    fullWidth
                    value={appRoleName}
                    onChange={(e) => { setAppRoleName(e.target.value); }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => props.onClick(appRoleName)}>{modeName}</Button>
                <Button color="primary" onClick={() => props.onClick("")}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
}

async function saveAppRole(data) {
    await userMaintenanceService.saveAppRole(data);
    const newData = await userMaintenanceService.getUserMaintenanceInfo();
    return newData.appRoleMaster;
}