import React, { useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { TextField, FormControlLabel, FormLabel, Button, Fab, FormGroup, FormControl, Checkbox, Select, MenuItem, InputLabel, FormHelperText, Chip } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Draggable } from 'react-smooth-dnd';
import { Save, ArrowBack, Delete, List } from "@material-ui/icons";
import moment from 'moment';
import arrayMove from 'array-move';
import utility from "../../../Utility";
import { HelpTooltip, HelpMessage } from './HelpTooltip'
import { TechCheckboxDialog } from "./TechCheckboxDialog";
import { YesNoDialog } from "../../Common/Dialogs";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: "10em",
        '& .MuiTextField-root': {
            margin: "0px 10px 20px 0px",
        },
        '& .MuiChip-root': {
            margin: "0.5em 0.5em 0.5em 0em"
        },
        '& .MuiSelect-select': {
            minWidth: '230px'
        },
        '& .MuiFormControl-root': {
            marginBottom: '1em'
        },

        '& .line': {
            verticalAlign: 'middle',
            display: 'flex',
            alignItems: 'center',
            '& .help': {
                marginLeft: '2px',
                marginRight: '5px',
            }
        },

        '& .draggable-chip': {
            cursor: 'grab !important',

            '&.dragging': {
                cursor: 'grabbing !important'
            }
        }
    },
    helpMessage: {
        fontSize: '14px'
    },
    fab: {
        position: 'fixed',
        bottom: "2rem",
        right: "2rem",
        '& .MuiFab-root': {
            marginLeft: "1em",
        },
    },
    button: {
        marginBottom: "1em"
    }
}));

export function EditProject(props) {
    const masterData = props.masterData;
    const project = props.project;
    const classes = useStyles();

    const requiredMessage = "必須項目です";
    const { handleSubmit, register, errors, watch, setValue } = useForm();

    // 変更フラグ
    const [update, setUpdate] = useState(false);
    // 確認ダイアログ表示フラグ
    const [open, setOpen] = useState(false);

    const [developmentMethod, setDevelopmentMethod] = useState(project.developmentMethodId);
    const [projectRole, setProjectRole] = useState(project.projectRoleId);
    const [projectRoleHasError, setProjectRoleHasError] = useState(false);
    const [workType, setWorkType] = useState(project.workTypeId);
    const [workTypeHasError, setWorkTypeHasError] = useState(false);
    const [workProcessIdList, setWorkProcessIdList] = useState(project.workProcessIdList);
    const [techUsed, setTechUsed] = useState(project.techUsed);
    const [techGenreId, setTechGenreId] = useState(0);

    const handleChange = () => {
        setUpdate(true);
    };

    /**
     * 開発手法変更イベント
     * @param {any} e
     */
    const handleDevelopmentMethodChange = e => {
        setDevelopmentMethod(e.target.value);
        setUpdate(true);
    };

    /**
     * 役割変更イベント
     * @param {any} e
     */
    const handleProjectRoleChange = (e) => {
        setProjectRole(e.target.value);
        e.target.value && setProjectRoleHasError(false);
        setUpdate(true);
    }

    /**
     * 作業種別変更イベント
     * @param {any} e
     */
    const handleWorkTypeChange = (e) => {
        setWorkType(e.target.value);
        e.target.value && setWorkTypeHasError(false);
        setUpdate(true);
    }

    /**
     * 作業工程変更イベント
     * @param {any} workProcessId
     * @param {any} checked
     */
    const handleWorkProcessChange = (workProcessId, checked) => {
        let workProcessIdListCopy = workProcessIdList.slice();
        if (checked) {
            workProcessIdListCopy.push(workProcessId);
        } else {
            const index = workProcessIdListCopy.indexOf(workProcessId);
            workProcessIdListCopy.splice(index, 1);
        }
        setWorkProcessIdList(workProcessIdListCopy);
        setUpdate(true);
    }

    /**
     * 開発環境変更イベント
     * @param {any} value
     * @param {any} techGenreId
     */
    const techUsedChange = (value, techGenreId) => {
        const techUsedCopy = techUsed.slice();
        const techUsedWithoutNowGenre = techUsedCopy[0] ? techUsedCopy.filter(t => t.techGenreId !== techGenreId) : [];
        const newTechUsed = techUsedWithoutNowGenre.concat(
            value.map((t, index) => (
                {
                    sortOrder: index + 1,
                    techGenreId: t.techGenreId,
                    techId: t.techId,
                    techVersionId: t.techVersionId,
                    hasTechVersion: t.techVersionId > 0
                }
            )));
        setTechUsed(newTechUsed);
        setUpdate(true);
    }

    /**
     * 開発環境ドロップイベント
     * @param {any} removedIndex
     * @param {any} addedIndex
     * @param {any} techGenreId
     */
    const techUsedOnDrop = (removedIndex, addedIndex, techGenreId) => {
        const techUsedCopy = techUsed.slice();

        //使用技術を今のジャンルとそれ以外に分ける
        const techUsedNowGenre = techUsedCopy[0] ? techUsedCopy.filter(t => t.techGenreId === techGenreId) : [];
        const techUsedWithoutNowGenre = techUsedCopy[0] ? techUsedCopy.filter(t => t.techGenreId !== techGenreId) : [];

        //今のジャンルの配列を入れ替え、sortOrderを変更する
        const techUsedNowGenreAfterSort = arrayMove(techUsedNowGenre, removedIndex, addedIndex).map((t, index) => (
            {
                sortOrder: index + 1,
                techGenreId: t.techGenreId,
                techId: t.techId,
                techVersionId: t.techVersionId,
                hasTechVersion: t.hasTechVersion
            }
        ));

        //他のジャンルの使用技術と入れ替えなおした今のジャンルの使用技術を結合する
        const newTechUsed = techUsedWithoutNowGenre.concat(techUsedNowGenreAfterSort);
        setTechUsed(newTechUsed);
        setUpdate(true);
    };

    /**
     * 開発環境削除イベント
     * @param {any} techId
     * @param {any} techVersionId
     */
    const techUsedDelete = (techId, techVersionId) => {
        //使用技術を削除する使用技術以外で絞りこむ
        const newTechUsed = techUsed.filter(x => !(x.techId === techId && x.techVersionId === techVersionId));
        setTechUsed(newTechUsed);
        setUpdate(true);
    };

    const deleteButton = project.projectId === 0
        ? <div></div>
        : <Button
            variant="contained"
            color="secondary"
            onClick={() => props.handleDeleteButton()}
            className={classes.button}
            startIcon={<Delete />}
        >
            削除
        </Button>;

    const openTechCheckboxDialog = techGenreId > 0;
    return (
        <Fragment>
            <form className={classes.root} onSubmit={handleSubmit((data) => {
                !projectRole && setProjectRoleHasError(true);
                !workType && setWorkTypeHasError(true);
                props.handleSaveButton(data, developmentMethod, projectRole, workType, workProcessIdList, techUsed)
            })}>
                {deleteButton}
                <div>
                    <TextField
                        name="startMonth"
                        label="開始年月"
                        type="month"
                        inputProps={{ max: "9999-12" }}
                        inputRef={register({
                            required: requiredMessage
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={Boolean(errors.startMonth || isAfter(watch("startMonth"), watch("endMonth")))}
                        helperText={errors.startMonth && errors.startMonth.message}
                        defaultValue={project.startMonth ? moment(project.startMonth, "YYYY-MM-DD").format("YYYY-MM") : null}
                        onChange={handleChange}
                    />
                    <TextField
                        name="endMonth"
                        label="終了年月"
                        type="month"
                        inputProps={{ max: "9999-12" }}
                        inputRef={register({
                            required: requiredMessage
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                        error={Boolean(errors.endMonth || isAfter(watch("startMonth"), watch("endMonth")))}
                        helperText={errors.endMonth && errors.endMonth.message}
                        defaultValue={project.endMonth ? moment(project.endMonth, "YYYY-MM-DD").format("YYYY-MM") : null}
                        onChange={handleChange}
                    />
                    <Button variant="contained" color='primary' onClick={() => setValue('endMonth', moment().format("YYYY-MM"))} style={{ marginTop: 10 }}>現在参画中</Button>
                    {
                        isAfter(watch("startMonth"), watch("endMonth")) &&
                        <p style={{ color: "#f44336", fontSize: "12px" }}>開始年月は終了年月より前に設定してください</p>
                    }
                </div>
                <div>
                    <div className="line">
                        <TextField
                            name="projectOverview"
                            label="プロジェクト概要"
                            inputRef={register({
                                validate: {
                                    max: value => utility.conuntHankakuZenkaku(value) <= 186 || '文字数上限を超えています'
                                }
                            })}
                            multiline
                            rows={3}
                            rowsMax={3}
                            variant='outlined'
                            error={Boolean(errors.projectOverview)}
                            helperText={errors.projectOverview && errors.projectOverview.message}
                            defaultValue={project.projectOverview}
                            fullWidth
                            onChange={handleChange}
                        />
                        <HelpTooltip message={HelpMessage.PROJECT_OVERVIEW} />
                    </div>
                    <div className="line">
                        <TextField
                            name="workContent"
                            label="作業詳細"
                            inputRef={register({
                                validate: {
                                    max: value => utility.conuntHankakuZenkaku(value) <= 186 || '文字数上限を超えています'
                                }
                            })}
                            multiline
                            rows={3}
                            rowsMax={3}
                            variant='outlined'
                            fullWidth
                            error={Boolean(errors.workContent)}
                            helperText={errors.workContent && errors.workContent.message}
                            defaultValue={project.workContent}
                            onChange={handleChange}
                        />
                        <HelpTooltip message={HelpMessage.WORK_CONTENT} />
                    </div>
                </div>
                <div className="line">
                    <TextField
                        name="wholeMemberCount"
                        label="全体人数"
                        type="number"
                        inputRef={register({
                            min: {
                                value: 1,
                                message: "1以上の数字を入力してください"
                            }
                        })}
                        error={Boolean(errors.wholeMemberCount)}
                        helperText={errors.wholeMemberCount && errors.wholeMemberCount.message}
                        defaultValue={project.wholeMemberCount ? project.wholeMemberCount : null}
                        onChange={handleChange}
                    />
                    <HelpTooltip message={HelpMessage.WHOLE_MEMBER_COUNT} />
                    <TextField
                        name="teamMemberCount"
                        label="チーム人数"
                        type="number"
                        inputRef={register({
                            required: requiredMessage,
                            min: {
                                value: 1,
                                message: "1以上の数字を入力してください"
                            }
                        })}
                        required
                        error={Boolean(errors.teamMemberCount)}
                        helperText={errors.teamMemberCount && errors.teamMemberCount.message}
                        defaultValue={project.teamMemberCount ? project.teamMemberCount : null}
                        onChange={handleChange}
                    />
                    <HelpTooltip message={HelpMessage.TEAM_MEMBER_COUNT} />
                </div>
                <div className="line">
                    <FormControl>
                        <InputLabel htmlFor="development-method">開発手法</InputLabel>
                        <Select
                            id="development-method"
                            value={developmentMethod ? developmentMethod : ""}
                            onChange={handleDevelopmentMethodChange}
                        >
                            <MenuItem value="">　</MenuItem>
                            {masterData.developmentMethodList.map(d =>
                                <MenuItem
                                    key={d.developmentMethodId}
                                    value={d.developmentMethodId}
                                >
                                    {d.developmentMethodName}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <HelpTooltip message={HelpMessage.DEVELOPMENT_METHOD} />
                </div>
                <div className="line">
                    <FormControl required error={projectRoleHasError}>
                        <InputLabel htmlFor="project-role">役割</InputLabel>
                        <Select
                            name="projectRole"
                            id="project-role"
                            value={projectRole}
                            onChange={handleProjectRoleChange}
                        >
                            {masterData.projectRoleList.map(d =>
                                <MenuItem
                                    key={d.projectRoleId}
                                    value={d.projectRoleId}
                                >
                                    {d.projectRoleName}
                                </MenuItem>
                            )}
                        </Select>
                        {projectRoleHasError && <FormHelperText>{requiredMessage}</FormHelperText>}
                    </FormControl>
                    <HelpTooltip message={HelpMessage.PROJECT_ROLE} />
                </div>
                <div className="line">
                    <FormControl required error={workTypeHasError}>
                        <InputLabel htmlFor="work-type">作業種別</InputLabel>
                        <Select
                            id="work-type"
                            value={workType}
                            onChange={handleWorkTypeChange}
                        >
                            {masterData.workTypeList.map(d =>
                                <MenuItem
                                    key={d.workTypeId}
                                    value={d.workTypeId}
                                >
                                    {d.workTypeName}
                                </MenuItem>
                            )}
                        </Select>
                        {workTypeHasError && <FormHelperText>{requiredMessage}</FormHelperText>}
                    </FormControl>
                    <HelpTooltip message={HelpMessage.WORK_TYPE} />
                </div>
                <div >
                    <FormControl>
                        <div className="line">
                            <FormLabel>作業工程</FormLabel>
                            <HelpTooltip style={{ marginBottom: '0.5rem' }} message={HelpMessage.WORK_PROCESS} />
                        </div>
                        <FormGroup row>
                            {masterData.workProcessList.map(workProcess => (
                                <FormControlLabel
                                    key={workProcess.workProcessId}
                                    label={workProcess.workProcessName}
                                    control={
                                        <Checkbox
                                            checked={workProcessIdList.includes(workProcess.workProcessId)}
                                            onChange={(e, checked) => handleWorkProcessChange(workProcess.workProcessId, checked)}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </div>
                <div style={{ width: "100%" }}>
                    <FormControl style={{ width: "100%" }}>
                        <div className="line">
                            <FormLabel>開発環境</FormLabel>
                            <HelpTooltip style={{ marginBottom: '0.5rem' }} message={HelpMessage.TECH_USED} />
                        </div>
                        <FormGroup>
                            {masterData.techGenreList.map(techGenre => (
                                <Autocomplete
                                    key={techGenre.techGenreId}
                                    multiple
                                    options={masterData.techAndTechVersionList.filter(t => t.techGenreId === techGenre.techGenreId)}
                                    getOptionLabel={(option) => option.description}
                                    value={
                                        techUsed.filter(tu => tu.techGenreId === techGenre.techGenreId).map(tu => {
                                            return masterData.techAndTechVersionList.filter(ttv => ttv.techId === tu.techId && ((!ttv.techVersionId && !tu.techVersionId) || ttv.techVersionId === tu.techVersionId))[0];
                                        })}
                                    renderTags={(value, getTagProps) => {
                                        return (
                                            <Container
                                                style={{
                                                    display: "inline-flex",
                                                    flexWrap: "wrap"
                                                }}
                                                orientation='horizontal'
                                                dragClass="dragging"
                                                onDrop={({ removedIndex, addedIndex }) => techUsedOnDrop(removedIndex, addedIndex, techGenre.techGenreId)}
                                            >
                                                {
                                                    value.map((option, i) => {
                                                        return (
                                                            <Draggable key={i}>
                                                                <Chip
                                                                    className="draggable-chip"
                                                                    label={option.description}
                                                                    onDelete={() => techUsedDelete(option.techId, option.techVersionId)}
                                                                />
                                                            </Draggable>
                                                        );
                                                    })
                                                }
                                            </Container>
                                        );
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <div className="line">
                                                <Button variant="contained" color="primary" startIcon={<List />} onClick={() => setTechGenreId(techGenre.techGenreId)} style={{ margin: "0 10px 0 0", minWidth: "85px" }}>一覧</Button>
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={techGenre.techGenreName}
                                                    style={{ width: "85%" }}
                                                />
                                            </div>
                                        )
                                    }}
                                    onChange={(e, value) => techUsedChange(value, techGenre.techGenreId)}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </div>

                <div className={classes.fab}>
                    <Fab
                        onClick={() => {
                            if (update) {
                                // 変更されていた場合
                                setOpen(true);
                            } else {
                                // 変更されていなかった場合
                                props.handleCancelButton();
                            }
                        }}
                        disabled={props.cancelButtonDisable}>
                        <ArrowBack />
                    </Fab>
                    <Fab type="submit" color="primary">
                        <Save />
                    </Fab>
                </div>
            </form >
            <YesNoDialog
                open={open}
                message='変更内容が破棄されますが、よろしいですか？'
                onClickYes={() => {
                    setOpen(false);
                    props.handleCancelButton();
                }}
                onClickNo={() => setOpen(false)} />
            <TechCheckboxDialog
                open={openTechCheckboxDialog}
                masterData={masterData}
                techGenreId={techGenreId}
                techUsed={techUsed}
                setTechGenreId={(genreId) => setTechGenreId(genreId)}
                techUsedChange={(techChecked, genreId) => techUsedChange(techChecked, genreId)}
            />
        </Fragment>
    );
}

/**
 * 開始終了の年月をチェックします。
 * 
 * @param {any} start
 * @param {any} end
 */
function isAfter(start, end) {
    if (start && end) {
        const sm = moment(start, "YYYY-MM");
        const em = moment(end, "YYYY-MM");
        return sm.isAfter(em);
    }
    return false;
}
