import React, { Component } from "react";
import { ViewProfile } from "./Parts/ViewProfile";
import { EditProfile } from "./Parts/EditProfile";
import { Loading } from "../Loading/Loading";
import profileService from "../../Service/ProfileService";
import { YesNoDialog } from '../Common/Dialogs';

const ProfileDisplay = {
    View: 'View',
    Register: 'Register',
    Edit: 'Edit'
};

export class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                userProfile: {
                    familyName: "",
                    firstName: "",
                    familyNameKana: "",
                    firstNameKana: "",
                    gender: 1,
                    birthday: "",
                    nearestStation: "",
                    finalEducation: "",
                    summary: "",
                    holdCertificationList: [],
                    experienceMonth: 0
                },
                certificationList: [],
            },
            display: "",
            loading: false,
            dialogOpen: false
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const response = await profileService.getProfile(this.props.userName);
        if (response.status === 200) {
            const apiData = await response.json();
            if (apiData.userProfile.isValid) {
                this.setState({
                    data: apiData,
                    display: ProfileDisplay.View,
                    loading: false
                });
            } else {
                this.setState({
                    data: apiData,
                    display: ProfileDisplay.Register,
                    loading: false
                });
            }
        } else {
            this.setState({ loading: false });
        }
    }

    /**
     * データを取得する
     * */
    async getData() {
        this.setState({ loading: true });
        const response = await profileService.getProfile(this.props.userName);
        if (response.status === 200) {
            const apiData = await response.json();
            this.setState({
                data: apiData,
                loading: false
            });
        } else {
            this.setState({ loading: false });
        }
    }

    /**
     * 保存ボタンが押されたときにデータ(所持資格以外)と所持資格をクラスフィールドに格納し、
     * 確認ダイヤログを開く
     * @所持資格以外のデータ {any} data
     * @所持資格 {any} holdCertificationList
     */
    handleSaveButton(data, holdCertificationList) {
        this.sendData = data;
        this.sendHoldCertificationList = holdCertificationList;
        this.setState({ dialogOpen: true });
    }

    /**
     * 保存時の確認ダイヤログでいいえボタンを押したときにダイヤログを閉じる
     * */
    handleNoButton() {
        this.setState({ dialogOpen: false });
    }

    /**
     * 保存時の確認ダイヤログではいボタンを押したときにダイヤログを閉じデータをサーバーに送信する
     * */
    async handleYesButton() {
        this.setState({
            loading: true,
            dialogOpen: false
        });
        const data = this.sendData;
        const holdCertificationList = this.sendHoldCertificationList;

        const saveData = {
            userName: this.props.userName,
            familyName: data.familyName,
            firstName: data.firstName,
            familyNameKana: data.familyNameKana,
            firstNameKana: data.firstNameKana,
            gender: parseInt(data.gender),
            birthday: data.birthday,
            nearestStation: data.nearestStation,
            finalEducation: data.finalEducation,
            summary: data.summary,
            remarks: data.remarks,
            holdCertificationList: holdCertificationList
        }
        await profileService.saveProfile(saveData);
        const response = await profileService.getProfile(this.props.userName);
        if (response.status === 200) {
            const apiData = await response.json();
            this.setState({
                data: apiData,
                loading: false,
                display: ProfileDisplay.View
            });
        } else {
            this.setState({ loading: false });
        }
    }

    handleEditButton() {
        this.setState({ display: ProfileDisplay.Edit });
    }

    handleCancelButton() {
        this.setState({ display: ProfileDisplay.View });
    }



    render() {
        const data = this.state.data;
        const cancelButtonDisable = this.state.display === ProfileDisplay.Register ? true : false;

        let element = <div></div>;
        switch (this.state.display) {
            case ProfileDisplay.View:
                element = <ViewProfile
                    data={data}
                    handleEditButton={() => this.handleEditButton()}
                    backButton={Boolean(this.props.userName)}
                    handleBackButton={() => this.props.handleBackButton()}
                    handleUploadButton={(e) => this.handleUploadButton(e)}
                    userName={this.props.userName}
                />;
                break;
            case ProfileDisplay.Edit:
            case ProfileDisplay.Register:
                element = <EditProfile
                    data={data}
                    handleSaveButton={(data, holdCertificationList) => this.handleSaveButton(data, holdCertificationList)}
                    handleCancelButton={() => this.handleCancelButton()}
                    cancelButtonDisable={cancelButtonDisable}
                    getData={() => this.getData()}
                    userName={this.props.userName}
                />;
                break;
            default:
                break;
        }

        return (
            <div>
                <Loading loading={this.state.loading} />
                {element}
                <YesNoDialog
                    open={this.state.dialogOpen}
                    message='保存しますか？'
                    onClickYes={() => this.handleYesButton()}
                    onClickNo={() => this.handleNoButton()} />
            </div>
        );
    }
}
