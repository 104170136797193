import React from 'react';
import { SummaryGroupPieChart } from './SummaryGroupChart';

export function ProjectAnalysis(props) {
    const projectAnalysis = props.projectAnalysis;
    return (
        <div>
            <div style={{ display: 'flex' }}>
                <SummaryGroupPieChart title="案件年月" backgroundColorPaletteName={["cb-YlGnBu"]} data={projectAnalysis.projectMonthSummaryGroup} />
                <SummaryGroupPieChart title="開発手法" backgroundColorPaletteName={["cb-YlGnBu", "cb-Set3"]} data={projectAnalysis.developmentMethodSummaryGroup} />
            </div>
            <div style={{ display: 'flex' }}>
                <SummaryGroupPieChart title="役割" backgroundColorPaletteName={["cb-YlGnBu", "cb-Set3"]} data={projectAnalysis.projectRoleSummaryGroup} />
                <SummaryGroupPieChart title="開発手法" backgroundColorPaletteName={["cb-YlGnBu", "cb-Set3"]} data={projectAnalysis.workTypeSummaryGroup} />
            </div>
        </div>);
}
