import React, { Component } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths, OperationContentCode } from './components/api-authorization/ApiAuthorizationConstants';
import './custom.css'
import { Profile } from './components/Profile/Profile';
import { Career } from './components/Career/Career';
import { Search } from './components/Search/Search';
import { Analysis } from './components/Analysis/Analysis';
import { TechMaintenance } from './components/TechMaintenance/TechMaintenance';
import { MasterMaintenance } from './components/MasterMaintenance/MasterMaintenance';
import { UserMaintenance } from './components/UserMaintenance/UserMaintenance';
import { TechSummary } from './components/TechSummary/TechSummary';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout {...this.props}>
                <AuthorizeRoute exact path='/' component={Home} role={OperationContentCode.VIEW_HOME} {...this.props} />
                <AuthorizeRoute exact path='/profile' component={Profile} role={OperationContentCode.VIEW_USER_PROFILE} {...this.props} />
                <AuthorizeRoute exact path='/techSummary' component={TechSummary} role={OperationContentCode.VIEW_CAREER} {...this.props} />
                <AuthorizeRoute exact path='/career' component={Career} role={OperationContentCode.VIEW_CAREER} {...this.props} />

                <AuthorizeRoute exact path='/search' component={Search} role={OperationContentCode.VIEW_SEARCH} {...this.props} />
                <AuthorizeRoute exact path='/analysis' component={Analysis} role={OperationContentCode.VIEW_ANALYSIS} {...this.props} />

                <AuthorizeRoute exact path='/techMaintenance' component={TechMaintenance} role={OperationContentCode.VIEW_TECH_MAINTENANCE} {...this.props} />
                <AuthorizeRoute exact path='/masterMaintenance' component={MasterMaintenance} role={OperationContentCode.VIEW_MASTER_MAINTENANCE} {...this.props} />
                <AuthorizeRoute exact path='/userMaintenance' component={UserMaintenance} role={OperationContentCode.VIEW_USER_MAINTENANCE} {...this.props} />

                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} {...this.props} />
            </Layout>
        );
    }
}
