import React from "react";
import moment from 'moment';
import { TableContainer, Table, TableRow, TableCell, TableBody, Paper, makeStyles, Chip, Fab, Typography, Link } from "@material-ui/core";
import { Edit, ArrowBack } from '@material-ui/icons';
import { Fragment } from "react";
import profileService from "../../../Service/ProfileService";

const useStyles = makeStyles({
    root: {
        marginBottom: "5em",
        '& .MuiTableCell-head': {
            width: "90px",
            fontWeight: "bold",
            backgroundColor: "#eaeff1"
        },
        '& .MuiChip-root': {
            margin: "0.5em 0.5em 0.5em 0em"
        }
    }, fab: {
        position: 'fixed',
        bottom: "2rem",
        right: "2rem",
        '& .MuiFab-root': {
            marginLeft: "1em",
        },
    }
});

export function ViewProfile(props) {
    const classes = useStyles();
    const userProfile = props.data.userProfile;
    const fileInfoList = props.data.fileInfoList;
    const gender = userProfile.gender === 1 ? "男性" : "女性";
    const experienceYear = Math.floor(userProfile.experienceMonth / 12) ? `${Math.floor(userProfile.experienceMonth / 12)}年` : "";
    const experienceMonth = userProfile.experienceMonth % 12 ? `${userProfile.experienceMonth % 12}ヵ月` : "";
    const backButton = props.backButton
        ? <Fab onClick={props.handleBackButton}><ArrowBack /></Fab>
        : <Fragment />;


    const handleFileLinkOnClick = (e, fileName) => {
        e.preventDefault();
        profileService.downloadAttachment(props.userName, fileName);
    };
    return (
        <div>
            <TableContainer className={classes.root} component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">名前</TableCell>
                            <TableCell>{`${userProfile.familyName}　${userProfile.firstName}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">カナ</TableCell>
                            <TableCell>{`${userProfile.familyNameKana}　${userProfile.firstNameKana}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">性別</TableCell>
                            <TableCell>{gender}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">生年月日</TableCell>
                            <TableCell>{userProfile.birthday
                                && `${moment(userProfile.birthday).format("YYYY年MM月DD日")}　${moment().diff(moment(userProfile.birthday), 'years')}歳`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">経験年数</TableCell>
                            <TableCell>{experienceYear + experienceMonth}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">最寄り駅</TableCell>
                            <TableCell>{userProfile.nearestStation}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">最終学歴</TableCell>
                            <TableCell>{userProfile.finalEducation}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">自己PR<br />その他</TableCell>
                            <TableCell>{userProfile.summary}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">資格</TableCell>
                            <TableCell>
                                {userProfile.holdCertificationList && userProfile.holdCertificationList.map(item =>
                                    <Chip key={item.certificationId} label={item.certificationName} />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">補足</TableCell>
                            <TableCell>{userProfile.remarks}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">添付<br />ファイル</TableCell>
                            <TableCell>
                                {
                                    fileInfoList.filter(x => x.canDownload).map(value => (
                                        <Typography key={value.fileName}>
                                            <Link href="#" onClick={(e) => handleFileLinkOnClick(e, value.fileName)}>{value.fileName}</Link>
                                        </Typography>
                                    ))
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.fab}>
                {backButton}
                <Fab color="primary" onClick={() => props.handleEditButton()} >
                    <Edit />
                </Fab>
            </div>
        </div >
    );
}