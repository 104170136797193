import ServiceCommon from "./ServiceCommon";

class SearchService extends ServiceCommon {
    async getMaster(techIdList, developmentMethodIdList, projectRoleIdList, workTypeIdList, workProcessIdList) {
        const strTechIdList = SeparateComma(techIdList);
        const strDevelopmentMethodIdList = SeparateComma(developmentMethodIdList);
        const strProjectRoleIdList = SeparateComma(projectRoleIdList);
        const strWorkTypeIdList = SeparateComma(workTypeIdList);
        const strWorkProcessIdList = SeparateComma(workProcessIdList);
        const response = await this.get('/api/Search/Search', { strTechIdList, strDevelopmentMethodIdList, strProjectRoleIdList, strWorkTypeIdList, strWorkProcessIdList });
        return response;
    }

    async getUserSkill(userName) {
        const response = await this.get(`/api/Search/GetUserSkill?userName=${userName}`);
        return response;
    }
}

const searchService = new SearchService();
export default searchService;

/**
 * リストをカンマで区切る、リストに値が入っていない場合空文字を返す
 * @param {any} list
 */
function SeparateComma(list) {
    const str = list ? list.join(',') : "";
    return str;
}