import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Select, MenuItem, FormControl, InputLabel, IconButton, Tooltip } from '@material-ui/core';
import heartRailsService from '../../../Service/HeartRailsService';
import { GpsFixed, Help } from '@material-ui/icons';


export function SelectStationsDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [data, setData] = React.useState({
        prefecture: [],
        line: [],
        station: [],
    });
    const [prefecture, setPrefecture] = React.useState("");
    const [line, setLine] = React.useState("");
    const [name, setName] = React.useState("");

    const handleEntering = () => {
        setData({
            prefecture: [],
            line: [],
            station: [],
        });

        heartRailsService.getPrefectures().then(data => {
            setData((prevState) => {
                const prefecture = data.response.prefecture ? data.response.prefecture : [];
                return { ...prevState, prefecture };
            });
            changePrefecture("東京都");
        });
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = (value) => {
        onClose(value);
    };

    const changePrefecture = (value) => {
        setPrefecture(value);
        heartRailsService.getLines(value).then(data => {
            setData((prevState) => {
                const line = data.response.line ? data.response.line : [];
                return { ...prevState, line };
            });
            setLine("");
        });
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth="sm"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">
                駅名検索
                <Tooltip title="駅名検索後、一覧から選択してください。検索は路線名か駅名を入力して検索するか、現在地から検索することができます。" placement="bottom-start">
                    <Help />
                </Tooltip>
            </DialogTitle>
            <DialogContent dividers>
                <div style={{
                    display: "flex",
                    flexFlow: "column"
                }}>
                    <FormControl>
                        <InputLabel htmlFor="select-prefecture">都道府県</InputLabel>
                        <Select
                            id="select-prefecture"
                            value={prefecture}
                            onChange={(e) => { changePrefecture(e.target.value); }}>
                            {data.prefecture.map((value, index) =>
                                <MenuItem key={index} value={value}>{value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel htmlFor="select-line">路線名</InputLabel>
                        <Select
                            id="select-line"
                            value={line}
                            onChange={(e) => { setLine(e.target.value); }}>
                            {data.line.map((value, index) =>
                                <MenuItem key={index} value={value}>{value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <TextField
                        name="name"
                        label="駅名"
                        value={name}
                        onChange={(e) => { setName(e.target.value); }}
                    />
                    <div style={{
                        verticalAlign: 'middle',
                        display: "flex",
                        alignItems: 'center'
                    }}>
                        <Tooltip title="現在地で検索します" placement="bottom-start">
                            <IconButton color="primary" component="span" style={{ float: "left" }} onClick={() => {
                                navigator.geolocation.getCurrentPosition((position) => {
                                    heartRailsService.getStations(null, null, null, position.coords.longitude, position.coords.latitude).then((data) => {
                                        setData((prevState) => {
                                            const station = data.response.station ? data.response.station : [];
                                            return { ...prevState, station };
                                        });
                                    });
                                });
                            }}>
                                <GpsFixed fontSize="small"/>
                            </IconButton>
                        </Tooltip>

                        <Button variant="contained" color="primary" style={{ margin: "0 0 0 auto" }} onClick={() => {
                            heartRailsService.getStations(line, name).then((data) => {
                                setData((prevState) => {
                                    const station = data.response.station ? data.response.station : [];
                                    return { ...prevState, station };
                                });
                            });
                        }}>検索</Button>
                    </div>
                </div>

                <StationTable
                    station={data.station}
                    onClick={(value) => {
                        handleOk(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <div>
                    <a target='_blank' rel="noopener noreferrer" href="https://www.heartrails.com/">HeartRails</a>
                </div>
                <div>
                    <Button autoFocus onClick={handleCancel} color="primary">キャンセル</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

function StationTable(props) {
    const station = props.station;
    if (!station || station.length === 0) {
        return (<div />)
    }

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: "bold", backgroundColor: "#eaeff1" }}>都道府県</TableCell>
                        <TableCell style={{ fontWeight: "bold", backgroundColor: "#eaeff1" }}>路線名</TableCell>
                        <TableCell style={{ fontWeight: "bold", backgroundColor: "#eaeff1" }}>駅名</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {station.map((row, index) => (
                        <TableRow
                            hover
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() => {
                                const value = `${row.line}　${row.name}駅`
                                props.onClick(value);
                            }}>
                            <TableCell>{row.prefecture}</TableCell>
                            <TableCell>{row.line}</TableCell>
                            <TableCell>{row.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
