import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

import logo from './img/logo.png';
import IMG1014993 from './img/1014993.jpg';
import IMG2086358 from './img/2086358.png';
import IMG2088170 from './img/2088170.png';
import IMG2099856 from './img/2099856.png';
import IMG2084496 from './img/2084496.png';

import IMG1142558 from './img/1142558.png';
import IMG327737 from './img/327737.png';
import IMG1024193 from './img/1024193.png';

import IMG1020483 from './img/1020483.png';
import IMG2037618 from './img/2037618.png';

const useStyles = makeStyles((theme) => ({
    root: {
        '& img': {
            width: '280px'
        },
        '& h4': {
            padding: '0.4em 0.5em',
            color: '#494949',
            background: '#f4f4f4',
            borderLeft: 'solid 5px #7db4e6',
            borderBottom: 'solid 3px #d7d7d7'
        },

        '& .triangle': {
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '30px 0 30px 50px',
            borderColor: 'transparent transparent transparent #ff00ff'
        },

        '& .point': {
            fontWeight: 'bold',
            background: 'linear-gradient(transparent 70%, #FFFF00 0%)'
        }
    },
    contents: {
        marginBottom: '2em',
        padding: '2em 2em 2em 2em',
        fontSize: '18px',

        '& .line': {
            display: 'flex'
        },

        '& .rightImage': {
            paddingLeft: '2em'
        },

        '& .lineCenter': {
            verticalAlign: 'middle',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    qa: {
        marginBottom: '2em',

        '& .question': {
            fontWeight: 'bold',
            marginBottom: '1em',
        },
        '& .answar': {
            marginLeft: '2em'
        }
    },
    balloon: {
        position: 'relative',
        display: 'inline-block',
        margin: '0 15px 0 0',
        padding: '0 5px',
        width: '40px',
        height: '40px',
        lineHeight: '40px',
        textAlign: 'center',
        color: '#FFF',
        fontSize: '20px',
        fontWeight: 'bold',
        background: '#ff8e9d',
        borderRadius: '50%',
        boxSizing: 'border-box',

        "&:before": {
            content: '""',
            position: 'absolute',
            top: '50%',
            right: '-20px',
            marginTop: '-8px',
            border: '8px solid transparent',
            borderLeft: '15px solid #ff8e9d',
            zIndex: '0',
        }
    }
}));

export function InfoDocument(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={logo} alt='logo' />
            <h4>career notesとは</h4>
            <Paper elevation={3}>
                <div className={classes.contents}>
                    <div className="line">
                        <div>
                            <span className='point'>career notes</span>はWEB上でスキルシートを作成、更新できるシステムです。<br />
                            <ul>
                                <li type="disc">外出先や自宅からすぐに経歴を更新できる(営業からExcelを送ってもらわなくてよい)</li>
                                <li type="disc">言語やFWなどを選択式にすることで粒度を揃える(人によって書いたり書かなかったりを減らす)</li>
                                <li type="disc">(将来的に)WELLSTONE内の平均スキルを出したり、スキルアップの方向性を出したりできる</li>
                            </ul>
                            などを目的にリリースしました。今回を機にスキルシートの刷新をお願いいたします！<br />
                        </div>
                        <div className="rightImage">
                            <img src={IMG1014993} alt='IMG1014993' />
                        </div>
                    </div>
                </div>
            </Paper>

            <h4>スキルシートの重要性</h4>
            <Paper elevation={3}>
                <div className={classes.contents}>
                    <div className="line">
                        <div>
                            <img src={IMG2086358} alt='IMG2086358' />
                        </div>
                        <div>
                            WELLSTONEに限らずSIビジネスはスキルシートから話が始まるケースがほとんどです。<br />
                            エンジニアが顧客と初めて対面するのは面談の場がメインだと思いますが、<br />
                            実際にはそれ以前に営業から、もしくは顧客担当者間の紹介でスキルシートが事前に提示され<br />
                            その内容を吟味した上で面談が組まれます。<br />
                            <br />
                            <ul>
                                <li type="disc">本当は経験があるのに、スキルシートに記載していないがために経験なしと判断された</li>
                                <li type="disc">トークに自信があり、面談ではうまくアピールできるのに、そもそも面談まで進まない</li>
                                <li type="disc">逆にトークが苦手で面談で詳細を突っ込まれると止まってしまう。。。</li>
                            </ul>
                            <br />
                                これらは非常に大きな機会損失でもったいない話です。スキルシートには自身の経験や強みを<br />
                                余すところなく記載し、むしろ面談では話すことがない、読むだけで終わるくらいの状態が理想的です。<br />
                        </div>
                    </div>
                </div>
            </Paper>

            <h4>スキルシート作成のポイント</h4>
            <Paper elevation={3}>
                <div className={classes.contents}>
                    <div className="line">
                        <img src={IMG2088170} alt='IMG2088170' />
                        <img src={IMG2099856} alt='IMG2099856' />
                    </div>

                    ≪初級編≫<br />
                    <ul>
                        <li type="disc">
                            とにかく漏れなく埋めましょう(MWやツール類は忘れがち)<br />
                            ※Excel,Word,PowerPointは基本的には記載不要です。Office系の使用頻度が高いPMOやプリセールス職、テスト現場にてExcelのみ使用していたケースなどは記載してください。
                        </li>
                        <li type="disc">バージョン情報もなるべく詳しく書きましょう(稀にバージョン経験で判断する顧客もいます)</li>
                        <li type="disc">作業工程は少しでも携わったことがあれば積極的に●をつけましょう</li>
                    </ul>
                    <br />
                    ※控えめな人が多いので「ちょっとした修正しか経験ないから●は付けられない」などという方も多いですが<br />
                    顧客側は常に人手不足ということもあり「ちょっとでも経験があれば検討したい！」と思っています。<br />
                    一方で、「基本設計を本格的に経験したい」と希望しても、●が一つも付いてない場合は、経験ゼロと<br />
                    判断され、面談まで進まないケースもあります。具体的な作業内容は面談時にしっかりと話せば<br />
                    ミスマッチは基本的に起こらないので、まずはチャンスを掴むべくスキルシート上は強気にいきましょう！<br />
                    <br />
                    <br />
                    ≪中級編≫<br />
                    <ul>
                        <li type="disc">経験ボリュームを明確にしましょう(作業詳細にステップ数、画面数、ksなど単位が分かるように記載)</li>
                    </ul>
                    <br />
                    ≪上級編≫<br />
                    <ul>
                        <li type="disc">課題解決能力が分かるように書きましょう(非機能要件の改善や要件定義工程でのブレークスルーなど)</li>
                    </ul>

                    <img src={IMG2084496} alt='IMG2084496' />
                </div>
            </Paper>

            <h4>顧客はここを見ている！</h4>
            <Paper elevation={3}>
                <div className={classes.contents}>
                    <div className="lineCenter">
                        <img src={IMG1142558} alt='IMG1142558' />
                        <div className='triangle' />
                        <img src={IMG327737} alt='IMG327737' />
                        <div className='triangle' />
                        <img src={IMG1024193} alt='IMG1024193' />
                    </div>

                    顧客の見ているであろうポイントを優先順位別に記載します。※顧客よって多少前後します<br />
                    <br />
                    <ol>
                        <li>開発言語は合致しているか(インフラ系であればOS,ME類)</li>
                        <li>経験工程は問題ないか</li>
                        <li>FW,DB,OSなどその他環境も合致しているか</li>
                        <li>経験年数や年齢は合致しているか<br />
                            ※本当は経験年数が少なくてもベテランより優秀な若手はいるし、逆に高齢でも若手よりパワフルに働くベテランもいますが、残念ながら経歴ベースで見送りになってしまうケースは多いです<br />
                        </li>
                        <li>若手の管理も任せられるか(リーダー経験)、アジャイルなど新しい開発手法に対応できるか。指示待ちではなく自主的に動いてくれそうか<br />
                            →この辺りは面談で実際に話してみて判断されるケースがほとんどです。一方で、このあたりの定性的なスキルをスキルシートベースでアピールできれば面談が入りやすいということになります。<br />
                            是非意識して記載してください！<br />
                        </li>
                    </ol>
                    <br />
                    もちろん上記にプラスして金額条件や会社としての信用を加味して最終判断となります。<br /><br />
                </div>
            </Paper>

            <h4>Q&A</h4>
            <Paper elevation={3}>
                <div className={classes.contents}>
                    <img src={IMG1020483} alt='IMG1020483' />

                    <div className={classes.qa}>
                        <div className='question'>
                            <div className={classes.balloon}>Q1</div>自分の経験言語や資格などが選択肢にない<br />
                        </div>
                        <div className='answar'>
                            営業にご連絡ください。マスタ追加します。ただし、あまりにマニアックな場合は、スペースの問題で追加できないケースがあります。その場合は作業詳細にテキストベースで記載してください。<br />
                        </div>
                    </div>

                    <div className={classes.qa}>
                        <div className='question'>
                            <div className={classes.balloon}>Q2</div>全体人数や開発手法、フレームワークなどが不明、又は思い出せない<br />
                        </div>
                        <div className='answar'>
                            どうしても書けない場合はブランクで大丈夫です。チーム人数、役割、作業種別は必須入力です。<br />
                        </div>
                    </div>

                    <div className={classes.qa}>
                        <div className='question'>
                            <div className={classes.balloon}>Q3</div>チーム人数、全体人数は何を書けばいいですか？<br />
                        </div>
                        <div className='answar'>
                            作業タスク単位のチームの人数です。WELLSTONE社員数ではありません。<br />
                        例えば顧客チームリーダー1名、自分、他社要員2名の場合は4名となります。<br />
                        一方、全体人数はエンドユーザーの担当社員、インフラチーム、デザインチームなどすべてひっくるめてPJ全体で動いている人数です。(正確な把握は難しいので、おおまかでいいです)<br />
                        </div>
                    </div>

                    <div className={classes.qa}>
                        <div className='question'>
                            <div className={classes.balloon}>Q4</div>長期間参画しているので役割が変わりました、いつ時点の役割を書けばいいですか？<br />
                        </div>
                        <div className='answar'>
                            基本的に最上位の役割を記載してください。<br />
                        例えばPG→SE→PLとステップアップしたけど、最後にちょっとメンバーとして実装のヘルプをして現場異動となった場合、記載するのは「PL」です。変遷の詳細は「作業詳細」に記載してください。<br />
                        </div>
                    </div>

                    <img src={IMG2037618} alt='IMG2037618' />
                </div>
            </Paper>
        </div>
    );
}