import React, { useState } from 'react';
import moment from 'moment';
import searchService from '../../../Service/SearchService';
import { TableContainer, Table, TableHead, TableCell, TableBody, Paper, TableRow, makeStyles, TableSortLabel, IconButton } from '@material-ui/core';
import { UsersTableRow } from './UsersTableRow';
import { Refresh } from '@material-ui/icons';

const useStyles = makeStyles({
    tableContainer: {
        overflow: "visible"
    },
    table: {
        minWidth: '750px',
        '& .MuiTableCell-head': {
            fontWeight: "bold",
            backgroundColor: "#eaeff1",
        },
        '& .MuiTableCell-root': {
            padding: "1px"
        },
        '& .MuiTableHead-root .MuiTableCell-root': {
            top: "64px"
        }
    },
});

const Columns = {
    name: 1,
    age: 3,
    experienceMonth: 4,
    updateDate: 6
}

export function UsersTable(props) {
    const classes = useStyles();
    const [state, setState] = useState({
        rows: props.users,
        column: Columns.experienceMonth,
        order: 'desc'
    });

    /**
     * ソートボタン押下時イベント
     * @param {any} column
     */
    const handleClickSort = (column) => {
        const isDesc = column === state.column && state.order === 'desc';
        const nextOrder = isDesc ? 'asc' : 'desc';
        const sortRule = { asc: [1, -1], desc: [-1, 1] };
        let sortedRows;
        switch (column) {
            case Columns.experienceMonth:
                sortedRows = state.rows.slice().sort((a, b) => {
                    if (a.userProfileModel.experienceMonth > b.userProfileModel.experienceMonth) {
                        return sortRule[nextOrder][0];
                    } else if (a.userProfileModel.experienceMonth < b.userProfileModel.experienceMonth) {
                        return sortRule[nextOrder][1];
                    } else {
                        return 0;
                    }
                });
                break;
            case Columns.age:
                sortedRows = state.rows.slice().sort((a, b) => {
                    if (moment(a.userProfileModel.birthday).isBefore(moment(b.userProfileModel.birthday))) {
                        return sortRule[nextOrder][0];
                    } else if (moment(a.userProfileModel.birthday).isAfter(moment(b.userProfileModel.birthday))) {
                        return sortRule[nextOrder][1];
                    } else {
                        return 0;
                    }
                });
                break;
            case Columns.updateDate:
                sortedRows = state.rows.slice().sort((a, b) => {
                    if (moment(a.lastUpdateDateTime).isAfter(moment(b.lastUpdateDateTime))) {
                        return sortRule[nextOrder][0];
                    } else if (moment(a.lastUpdateDateTime).isBefore(moment(b.lastUpdateDateTime))) {
                        return sortRule[nextOrder][1];
                    } else {
                        return 0;
                    }
                });
                break;
            case Columns.name:
                sortedRows = state.rows.slice().sort((a, b) => {
                    //苗字と名前を連結する
                    const aName = a.userProfileModel.familyNameKana + a.userProfileModel.firstNameKana;
                    const bName = b.userProfileModel.familyNameKana + b.userProfileModel.firstNameKana;

                    if (aName > bName) {
                        return sortRule[nextOrder][0];
                    } else if (aName < bName) {
                        return sortRule[nextOrder][1];
                    } else {
                        return 0;
                    }
                });
                break;
            default:
                sortedRows = state.rows.slice();
                break;
        }
        sortedRows.forEach(x => {
            x.open = false;
        });
        setState({
            column,
            order: nextOrder,
            rows: sortedRows
        });
    };

    const getUserSkill = async (userName) => {
        const user = state.rows.filter(x => x.userName === userName)[0];
        const userIndex = state.rows.indexOf(user);
        if (!(state.rows[userIndex].open)) {
            const response = await searchService.getUserSkill(userName);
            const apiData = await response.json();
            const dataForSetSkillList = apiData.sort((a, b) => {
                if (a.techGenreId > b.techGenreId) return 1;
                if (a.techGenreId < b.techGenreId) return -1;
                if (a.sortOrder > b.sortOrder) return 1;
                if (a.sortOrder < b.sortOrder) return -1;
                return 0;
            });
            state.rows[userIndex].skillList = dataForSetSkillList;
        }
        state.rows[userIndex].open = !(state.rows[userIndex].open);
    };

    const rowsAfterFilter = props.searchName
        ? state.rows.filter(x => {
            //姓名、フリガナ、ひらがなのふりがなを全て繋いだ文字列を作る
            const nameAndNameKana = x.userProfileModel.familyName + x.userProfileModel.firstName
                + x.userProfileModel.familyNameKana + x.userProfileModel.firstNameKana
                + kanaToHira(x.userProfileModel.familyNameKana) + kanaToHira(x.userProfileModel.firstNameKana);

            //検索文字列をスペースで分ける
            const searchNameSplitBlank = props.searchName.replaceAll(' ', '　').split('　');

            //AND検索（一つでも検索文字列が含まれていないものがあったらfalseを返す）
            for (let s of searchNameSplitBlank) {
                const index = nameAndNameKana.indexOf(s);
                if (index === -1) {
                    return false;
                }
            }
            return true;
        })
        : state.rows;

    return (
        <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow style={{ height: '50px' }}>
                        <TableCell style={{ width: 58 }} >
                            <IconButton
                                onClick={() => props.handleReload()}
                            >
                                <Refresh />
                            </IconButton>
                        </TableCell>
                        <TableCell style={{ width: 143 }}>
                            <TableSortLabel
                                active={state.column === Columns.name}
                                direction={state.column === Columns.name ? state.order : 'desc'}
                                onClick={() => { handleClickSort(Columns.name) }}
                            >
                                氏名
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: 56 }}>性別</TableCell>
                        <TableCell style={{ width: 70 }}>
                            <TableSortLabel
                                active={state.column === Columns.age}
                                direction={state.column === Columns.age ? state.order : 'desc'}
                                onClick={() => { handleClickSort(Columns.age) }}
                            >
                                年齢
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: 142 }}>
                            <TableSortLabel
                                active={state.column === Columns.experienceMonth}
                                direction={state.column === Columns.experienceMonth ? state.order : 'desc'}
                                onClick={() => { handleClickSort(Columns.experienceMonth) }}
                            >
                                経験年数
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: 345 }}>最寄り駅</TableCell>
                        <TableCell style={{ width: 252 }}>
                            <TableSortLabel
                                active={state.column === Columns.updateDate}
                                direction={state.column === Columns.updateDate ? state.order : 'desc'}
                                onClick={() => { handleClickSort(Columns.updateDate) }}
                            >
                                更新日
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: 50 }} />
                        <TableCell style={{ width: 50 }} />
                        <TableCell style={{ width: 50 }} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsAfterFilter.map((user, index) =>
                        <UsersTableRow
                            key={index}
                            user={user}
                            masterData={props.masterData}
                            handleDetailButton={userName => props.handleDetailButton(userName)}
                            handleProfileButton={userName => props.handleProfileButton(userName)}
                            getUserSkill={(userName) => getUserSkill(userName)}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

/**
 * カタカナを平仮名に変換
 * @param {any} str
 */
function kanaToHira(str) {
    return str.replace(/[\u30a1-\u30f6]/g, function (match) {
        var chr = match.charCodeAt(0) - 0x60;
        return String.fromCharCode(chr);
    });
}
