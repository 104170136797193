import ServiceCommon from "./ServiceCommon";

class UserRoleService extends ServiceCommon {
    async getUserRole() {
        const response = await this.get('/api/UserRole/getUserRole');
        if (response.status === 200) {
            return await response.json();
        }
        return null;
    }
}

const userRoleService = new UserRoleService();
export default userRoleService;