import React, { Fragment } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css'
import { makeStyles, Button, IconButton } from '@material-ui/core';
import SaveFab from '../../Common/SaveFab';
import { Add, AddBox, AddBoxOutlined, AddBoxSharp } from '@material-ui/icons';


const useStyles = makeStyles({
    button: {
        margin: '0 0 0 auto',
        display: 'block'
    },
    table: {
        '& .id-column': {
            textAlign: 'center',
            backgroundColor: '#ffffc1'
        },
        '& .name-column': {

        },
        '& .version-column': {

        }
    },
});

export function TechGroupTable(props) {
    const classes = useStyles();
    const hotTableComponent = React.createRef();

    const techGroupList = props.data.techGenreList.filter(tg => tg.techGenreId === props.genre)[0].techGroupList;
    return (
        <Fragment>
            <IconButton
                className={classes.button}
                color='primary'
                onClick={() => {
                    const hotInstance = hotTableComponent.current.hotInstance;
                    hotInstance.alter('insert_row', hotInstance.countRows());
                    hotInstance.selectCell(hotInstance.countRows() - 1, 1);
                }}
            >
                <AddBoxSharp />
            </IconButton>
            <HotTable
                ref={hotTableComponent}
                className={classes.table}
                data={techGroupList}
                rowHeaders={true}
                colHeaders={['ID', 'グループ名', '技術編集']}
                fillHandle={false}
                manualRowMove={true}
                contextMenu={{
                    items: {
                        'row_above': { name: '上に1行挿入' },
                        'row_below': { name: '下に1行挿入' },
                        "hsep2": "---------",
                        'undo': { name: '元に戻す' }
                    }
                }}
                columns={[
                    /// グループID
                    {
                        className: 'id-column',
                        readOnly: true,
                        width: "50",
                        data: (row, value) => {
                            if (!row.techGroupId) {
                                row.techGroupId = props.index.nextIndexString();
                            }
                            return row.techGroupId;
                        }
                    },
                    /// グループ名
                    {
                        className: 'name-column',
                        readOnly: false,
                        width: "300",
                        data: 'techGroupName'
                    }
                ]}
                beforeChange={
                    (changes, source) => {
                        const hotInstance = hotTableComponent.current.hotInstance;
                        const dataList = hotInstance.getData();
                        const result = dataList.map((data, i) => {
                            const id = data[0] ? data[0] : 0;
                            const name = data[1];
                            return {
                                id,
                                name,
                                sortOrder: i + 1,
                                isRegister: !id
                            };
                        });
                    }
                }
                afterCreateRow={(index, amount, source) => {
                    techGroupList[index].techList = [];
                    setSortOrder(hotTableComponent.current.hotInstance, techGroupList);
                }}
                afterRowMove={(movedRows) => {
                    setSortOrder(hotTableComponent.current.hotInstance, techGroupList);
                }}
            />
        </Fragment>
    );
}


/**
 * ソート順を更新します。
 * 
 * @param {any} hotInstance
 * @param {any} techGroupList
 */
function setSortOrder(hotInstance, techGroupList) {
    const dataList = hotInstance.getData();
    dataList.map((data, index) => {
        const techGroup = techGroupList.filter(x => x.techGroupId === data[0])[0];
        techGroup.sortOrder = index + 1;
    });
}