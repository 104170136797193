import palette from 'google-palette';

/**
 * チャートデータを作成
 * @param {any} techGroupList
 */
export function createBarChartData(techGroupList) {
    const header = ['グループ'];
    const data = [header.concat(techGroupList[0].summaryGroup.summaryGroupDetailList.map(x => x.description))];
    techGroupList.forEach(tg => {
        const countData = tg.summaryGroup.summaryGroupDetailList.map(x => x.count);
        data.push([tg.techGroupName].concat(countData));
    });
    return data;
}

/**
 * チャートデータを作成
 * @param {any} summaryGroup
 */
export function createPieChartData(summaryGroup) {
    const data = [['グループ', '人数']];
    summaryGroup.summaryGroupDetailList.forEach(sg => {
        data.push([sg.description, sg.count]);
    });
    return data;
}

/**
 * チャートデータを作成
 * @param {any} summaryGroup
 */
export function createLineChartData(summaryGroup) {
    const data = [['グループ', '案件数']];
    summaryGroup.summaryGroupDetailList.forEach(sg => {
        data.push([sg.description, sg.count]);
    });
    return data;
}

/**
 * パレット名から色リストを取得
 * @param {any} paletteName
 */
export function getColors(paletteNames) {
    const colors = [];
    paletteNames.forEach((paletteName) => {
        const paletteList = palette(paletteName, 9);
        paletteList.forEach((hex) => {
            colors.push('#' + hex);
        })
    });
    return colors;
}

/**
 * 文字色を背景色から判定し、白と黒の配列を取得
 * @param {any} colors
 */
export function getTextColors(colors) {
    return colors.map((hexcolor) => {
        var r = parseInt(hexcolor.substr(1, 2), 16);
        var g = parseInt(hexcolor.substr(3, 2), 16);
        var b = parseInt(hexcolor.substr(5, 2), 16);
        return ((((r * 299) + (g * 587) + (b * 114)) / 1000) < 128) ? "white" : "black";
    });
}
