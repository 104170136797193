import ServiceCommon from "./ServiceCommon";

class TechMaintenanceService extends ServiceCommon {
    async getMaster() {
        const response = await this.get('/api/TechMaintenance/getMaster');
        if (response.status === 200) {
            return await response.json();
        }
        return null;
    }

    async saveTechInfo(data) {
        const response = await this.post('/api/TechMaintenance/SaveTechInfo', data);
        return response;
    }
}

const techMaintenanceService = new TechMaintenanceService();
export default techMaintenanceService;