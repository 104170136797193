import React from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        position: 'absolute',
        marginRight: theme.spacing(2),
        width: '180px'
    },
    main: {
        marginLeft: '200px'
    },
    selected: {
        fontWeight: 'bold',
        backgroundColor: 'rgb(232, 244, 253)'
    }
}));

export default function MaintenanceLayout(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <MenuList>
                    {props.menulist.map((item) => {
                        const className = item.key === props.value ? classes.selected : '';
                        return (<MenuItem key={item.key} className={className} onClick={() => {
                            if (item.onClick) {
                                item.onClick(item.key);
                            }
                        }}>{item.body}</MenuItem>);
                    })}
                </MenuList>
            </Paper>
            <main className={classes.main}>
                {props.children}
            </main>
        </div>
    );
}
