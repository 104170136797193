import ServiceCommon from "./ServiceCommon";


class CareerService extends ServiceCommon {
    async getCareer(userName) {
        const response = await this.get("/api/Career/GetCareer", { userName });
        return response;
    }

    async saveProject(project) {
        const response = await this.post('/api/Career/SaveProject', project);
        return response;
    }

    async deleteProject(projectId) {
        const response = await this.post('/api/Career/DeleteProject', projectId);
        return response;
    }

    excelDownload(userName, excelFormatType) {
        const param = {
            userName,
            logoType: excelFormatType && excelFormatType.logoType,
            nameType: excelFormatType && excelFormatType.nameType
        };
        this.download("/api/Career/Download", param);
    }
}

const NameType = {
    /// <summary>フルネーム</summary>
    FullName: 1,
    /// <summary>イニシャル</summary>
    Initial: 2
};

const LogoType = {
    /// <summary>出力する</summary>
    Output: 1,
    /// <summary>出力しない</summary>
    NoneOutput: 2
};

export const ExcelFormatType = [
    {
        id: 1,
        display: "フルネーム出力",
        logoType: LogoType.Output,
        nameType: NameType.FullName
    },
    {
        id: 2,
        display: "イニシャル出力",
        logoType: LogoType.Output,
        nameType: NameType.Initial
    },
    {
        id: 3,
        display: "フルネーム/ロゴ無",
        logoType: LogoType.NoneOutput,
        nameType: NameType.FullName
    },
    {
        id: 4,
        display: "イニシャル/ロゴ無",
        logoType: LogoType.NoneOutput,
        nameType: NameType.Initial
    },
];

const careerService = new CareerService();
export default careerService;