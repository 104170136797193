import React, { Fragment } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css'
import { makeStyles, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { TechVersionTableDialog } from './TechVersionTableDialog';
import SaveFab from '../../Common/SaveFab';
import { AddBoxSharp } from '@material-ui/icons';


const useStyles = makeStyles({
    button: {
        margin: '0 0 0 auto',
        display:'block'
    },
    table: {
        '& .id-column': {
            textAlign: 'center',
            backgroundColor: '#ffffc1'
        },
        '& .name-column': {

        },
        '& .version-column': {

        }
    },
});

export function TechTable(props) {
    const classes = useStyles();
    const hotTableComponent = React.createRef();

    const splitCharacter = '. ';
    const [techId, setTechId] = useState(0);
    const techGroupList = props.data.techGenreList.filter(tg => tg.techGenreId === props.genre)[0].techGroupList;
    const techList = props.data.techGenreList.filter(tg => tg.techGenreId === props.genre)[0].techList;
    const techVersionList = props.data.techVersionList;
    const techGroupListForDropdown = techGroupList.map(t => `${t.techGroupId}${splitCharacter}${t.techGroupName}`);

    const techVersionDialog = techId
        ? <TechVersionTableDialog techId={techId} handleClose={() => setTechId(0)} data={props.data} index={props.index} techList={techList} />
        : <Fragment />


    return (
        <Fragment>
            <IconButton
                className={classes.button}
                color='primary'
                onClick={() => {
                    const hotInstance = hotTableComponent.current.hotInstance;
                    hotInstance.alter('insert_row', hotInstance.countRows());
                    hotInstance.selectCell(hotInstance.countRows() - 1, 2);
                }}
            >
                <AddBoxSharp />
            </IconButton>
            <HotTable
                ref={hotTableComponent}
                className={classes.table}
                data={techList}
                rowHeaders={true}
                colHeaders={['ID', '技術グループ', '技術名', 'バージョン選択']}
                fillHandle={false}
                manualRowMove={true}
                contextMenu={{
                    items: {
                        'row_above': { name: '上に1行挿入' },
                        'row_below': { name: '下に1行挿入' },
                        "hsep2": "---------",
                        'undo': { name: '元に戻す' }
                    }
                }}
                columns={[
                    /// 技術ID
                    {
                        className: 'id-column',
                        readOnly: true,
                        width: "50",
                        data: (row, value) => {
                            if (!row.techId) {
                                row.techId = props.index.nextIndexString();
                            }
                            return row.techId;
                        }
                    },
                    {
                        width: "250",
                        type: 'dropdown',
                        allowInvalid: false,
                        source: techGroupListForDropdown,
                        data: (row, value) => {
                            if (typeof value === 'undefined') {
                                if (!row.techGroupId) {
                                    row.techGroupId = techGroupList[0].techGroupId;
                                }
                                return `${row.techGroupId}${splitCharacter}${techGroupList.filter(tg => tg.techGroupId === row.techGroupId)[0].techGroupName}`
                            } else {
                                const techGroupIdName = value.split(splitCharacter);
                                row.techGroupId = parseInt(techGroupIdName[0]) ? parseInt(techGroupIdName[0]) : techGroupIdName[0];
                            }

                        }

                    },
                    /// 技術名
                    {
                        className: 'name-column',
                        readOnly: false,
                        width: "200",
                        data: 'techName'
                    },
                    {
                        readOnly: true,
                        width: "350",
                        data: (row, value) => {
                            const versionList = techList.filter(x => x.techId === row.techId)[0].techAndTechVersionList.map(x => x.techVersionName);
                            return `<a class="version-link" href="javascript:void(0)">${versionList && versionList.length > 0 ? versionList.join('、') : '(no version)'}</a>`;
                        },
                        renderer: 'html'
                    }
                ]}
                afterOnCellMouseDown={(e, coords, TD) => {
                    if (e.target.classList.contains("version-link")) {
                        const clickTechId = hotTableComponent.current.hotInstance.getDataAtCell(coords.row, 0);
                        setTechId(clickTechId);
                    }
                }}
                beforeChange={() => {
                    const hotInstance = hotTableComponent.current.hotInstance;
                    const dataList = hotInstance.getData();
                    const result = dataList.map((data, i) => {
                        const id = data[0] ? data[0] : 0;
                        const groupId = parseInt(data[1].split(splitCharacter)[0]);
                        const name = data[2];
                        return {
                            id,
                            groupId,
                            name,
                            sortOrder: i + 1,
                            isRegister: !id
                        };
                    });
                }}
                afterCreateRow={(index, amount, source) => {
                    techList[index].techAndTechVersionList = [];
                    setSortOrder(hotTableComponent.current.hotInstance, techList);
                }}
                afterRowMove={(movedRows) => {
                    setSortOrder(hotTableComponent.current.hotInstance, techList);
                }}
            />
            {techVersionDialog}
        </Fragment>
    );
}

/**
 * ソート順を更新します。
 * 
 * @param {any} hotInstance
 * @param {any} techList
 */
function setSortOrder(hotInstance, techList) {
    const dataList = hotInstance.getData();
    dataList.map((data,index) => {
        const tech = techList.filter(x => x.techId === data[0])[0];
        tech.sortOrder = index + 1;
    });
}