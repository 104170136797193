import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Home, Person, Work, Search, Group, Assignment, Settings, ShowChart, Build } from '@material-ui/icons';
import { OperationContentCode } from '../api-authorization/ApiAuthorizationConstants';

function onMenuClick() {
    window.scrollTo(0, 0);
}

const MenuItem = {
    VIEW_HOME: (
        <ListItem button component={Link} to="/" onClick={onMenuClick}>
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary='ホーム' />
        </ListItem>),
    VIEW_USER_PROFILE: (
        <ListItem button component={Link} to="/profile" onClick={onMenuClick}>
            <ListItemIcon><Person /></ListItemIcon>
            <ListItemText primary='基本情報' />
        </ListItem>),
    VIEW_TECH_SUMMARY: (
        <ListItem button component={Link} to="/techSummary" onClick={onMenuClick}>
            <ListItemIcon><Build /></ListItemIcon>
            <ListItemText primary='主要技術情報' />
        </ListItem>),
    VIEW_CAREER: (
        <ListItem button component={Link} to="/career" onClick={onMenuClick}>
            <ListItemIcon><Work /></ListItemIcon>
            <ListItemText primary='経歴情報' />
        </ListItem>),
    VIEW_SEARCH: (
        <ListItem button component={Link} to="/search" onClick={onMenuClick}>
            <ListItemIcon><Search /></ListItemIcon>
            <ListItemText primary='経歴検索' />
        </ListItem>),
    VIEW_ANALYSIS: (
        <ListItem button component={Link} to="/analysis" onClick={onMenuClick}>
            <ListItemIcon><ShowChart /></ListItemIcon>
            <ListItemText primary='分析' />
        </ListItem>),
    VIEW_TECH_MAINTENANCE: (
        <ListItem button component={Link} to="/techMaintenance" onClick={onMenuClick}>
            <ListItemIcon><Settings /></ListItemIcon>
            <ListItemText primary='技術情報管理' />
        </ListItem>),
    VIEW_MASTER_MAINTENANCE: (
        <ListItem button component={Link} to="/masterMaintenance" onClick={onMenuClick}>
            <ListItemIcon><Assignment /></ListItemIcon>
            <ListItemText primary='マスター管理' />
        </ListItem>),
    VIEW_USER_MAINTENANCE: (
        <ListItem button component={Link} to="/userMaintenance" onClick={onMenuClick}>
            <ListItemIcon><Group /></ListItemIcon>
            <ListItemText primary='ユーザー管理' />
        </ListItem>)
};

export class MenuList extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    hasRole(...values) {
        if (!this.props.userRole || !this.props.userRole.operationContentCode) {
            return false;
        }
        const codes = this.props.userRole.operationContentCode.slice();
        return codes.some(x => values.some(val => x === val));
    }

    render() {
        if (!this.props.userRole) {
            return (<Fragment />);
        }

        return (
            <Fragment>
                {this.hasRole(OperationContentCode.VIEW_HOME) ?
                    (<Fragment>
                        <Divider />
                        <List>
                            {MenuItem.VIEW_HOME}
                        </List>
                    </Fragment>)
                    : <Fragment />
                }

                {this.hasRole(OperationContentCode.VIEW_USER_PROFILE, OperationContentCode.VIEW_CAREER) ?
                    (<Fragment>
                        <Divider />
                        <List>
                            {this.hasRole(OperationContentCode.VIEW_USER_PROFILE) && MenuItem.VIEW_USER_PROFILE}
                            {this.hasRole(OperationContentCode.VIEW_CAREER) && MenuItem.VIEW_TECH_SUMMARY}
                            {this.hasRole(OperationContentCode.VIEW_CAREER) && MenuItem.VIEW_CAREER}
                        </List>
                    </Fragment>)
                    : <Fragment />
                }

                {this.hasRole(OperationContentCode.VIEW_SEARCH, OperationContentCode.VIEW_ANALYSIS) ?
                    (<Fragment>
                        <Divider />
                        <List>
                            {this.hasRole(OperationContentCode.VIEW_SEARCH) && MenuItem.VIEW_SEARCH}
                            {this.hasRole(OperationContentCode.VIEW_ANALYSIS) && MenuItem.VIEW_ANALYSIS}
                        </List>
                    </Fragment>)
                    : <Fragment />
                }

                {this.hasRole(OperationContentCode.VIEW_TECH_MAINTENANCE, OperationContentCode.VIEW_MASTER_MAINTENANCE, OperationContentCode.VIEW_USER_MAINTENANCE) ?
                    (<Fragment>
                        <Divider />
                        <List>
                            {this.hasRole(OperationContentCode.VIEW_TECH_MAINTENANCE) && MenuItem.VIEW_TECH_MAINTENANCE}
                            {this.hasRole(OperationContentCode.VIEW_MASTER_MAINTENANCE) && MenuItem.VIEW_MASTER_MAINTENANCE}
                            {this.hasRole(OperationContentCode.VIEW_USER_MAINTENANCE) && MenuItem.VIEW_USER_MAINTENANCE}
                        </List>
                    </Fragment>)
                    : <Fragment />
                }
            </Fragment >
        );
    }
}
