import React from 'react';
import { createBarChartData, createPieChartData, createLineChartData, getColors, getTextColors } from './ChartData';
import Chart from "react-google-charts";

export function SummaryGroupPieChart(props) {
    const backgroundColor = getColors(props.backgroundColorPaletteName);
    const textColor = getTextColors(backgroundColor);
    const data = createPieChartData(props.data);

    return (<Chart
        width={'500px'}
        height={'300px'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
            title: props.title,
            colors: backgroundColor,
            // Just add this option
            is3D: false,
            slices: textColor.map(color => {
                return {
                    textStyle: {
                        color
                    }
                };
            })
        }}
        rootProps={{ 'data-testid': '2' }}
    />);
}

export function SummaryGroupBarChart(props) {
    const backgroundColor = getColors(props.backgroundColorPaletteName);
    const data = createBarChartData(props.data);
    return (<Chart
        width={'600px'}
        height={'400px'}
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
            isStacked: true,
            colors: backgroundColor,
            chart: {
                title: props.title,
                subtitle: '',
            },
            vAxis: {
                title: props.title,
            },
        }}
        // For tests
        rootProps={{ 'data-testid': '2' }}
    />);
}

export function SummaryGroupLineChart(props) {
    const data = createLineChartData(props.data);
    return (<Chart
        width={'1000px'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
            chart: {
                title: props.title,
                subtitle: '',
            },
            hAxis: {
                title: '年月',
            },
            vAxis: {
                title: '件',
            },
        }}
        rootProps={{ 'data-testid': '1' }}
    />);
}
