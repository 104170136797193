import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, makeStyles, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, FormGroup, Checkbox, FormLabel, DialogContent, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    autoComplete: {
        marginBottom: 10
    },
    content: {
        '& .MuiPaper-root ': {
            padding: 15,
            marginBottom: 10
        }
    }
}));

export function SearchDialog(props) {
    const classes = useStyles();
    const masterData = props.masterData;
    const params = props.params;
    const [techList, setTechList] = useState(params.techIdList ? masterData.techList.filter(x => params.techIdList.includes(x.techId)) : []);
    const [workProcessIdList, setWorkProcessIdList] = useState(params.workProcessIdList ? params.workProcessIdList : []);
    const [workTypeIdList, setWorkTypeIdList] = useState(params.workTypeIdList ? params.workTypeIdList : []);
    const [projectRoleIdList, setProjectRoleIdList] = useState(params.projectRoleIdList ? params.projectRoleIdList : []);
    const [developmentMethodIdList, setDevelopmentMethodIdList] = useState(params.developmentMethodIdList ? params.developmentMethodIdList : []);

    /**
     * 技術変更イベント
     * @param {any} value
     */
    const handleTechChange = (value) => {
        setTechList(value);
    }
    /**
     * 開発手法変更イベント
     * @param {any} developmentMethodId
     * @param {any} checked
     */
    const handleDevelopmentMethodChange = (developmentMethodId, checked) => {
        let developmentMethodIdListCopy = developmentMethodIdList.slice();
        if (checked) {
            developmentMethodIdListCopy.push(developmentMethodId);
        } else {
            const index = developmentMethodIdListCopy.indexOf(developmentMethodId);
            developmentMethodIdListCopy.splice(index, 1);
        }
        setDevelopmentMethodIdList(developmentMethodIdListCopy);
    }

    /**
     * 役割変更イベント
     * @param {any} projectRoleId
     * @param {any} checked
     */
    const handleProjectRoleChange = (projectRoleId, checked) => {
        let projectRoleIdListCopy = projectRoleIdList.slice();
        if (checked) {
            projectRoleIdListCopy.push(projectRoleId);
        } else {
            const index = projectRoleIdListCopy.indexOf(projectRoleId);
            projectRoleIdListCopy.splice(index, 1);
        }
        setProjectRoleIdList(projectRoleIdListCopy);
    }

    /**
     * 作業種別変更イベント
     * @param {any} workTypeId
     * @param {any} checked
     */
    const handleWorkTypeChange = (workTypeId, checked) => {
        let workTypeIdListCopy = workTypeIdList.slice();
        if (checked) {
            workTypeIdListCopy.push(workTypeId);
        } else {
            const index = workTypeIdListCopy.indexOf(workTypeId);
            workTypeIdListCopy.splice(index, 1);
        }
        setWorkTypeIdList(workTypeIdListCopy);
    }

    /**
     * 作業工程変更イベント
     * @param {any} workProcessId
     * @param {any} checked
     */
    const handleWorkProcessChange = (workProcessId, checked) => {
        let workProcessIdListCopy = workProcessIdList.slice();
        if (checked) {
            workProcessIdListCopy.push(workProcessId);
        } else {
            const index = workProcessIdListCopy.indexOf(workProcessId);
            workProcessIdListCopy.splice(index, 1);
        }
        setWorkProcessIdList(workProcessIdListCopy);
    }
    return (
        <Dialog fullScreen open={props.open} onClose={props.handleClose}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>

                    </Typography>
                    <Button color='inherit' onClick={() => props.handleSearchClearButton()}>クリア</Button>
                    <Button autoFocus color="inherit" onClick={() => props.handleSearchButton(techList, developmentMethodIdList, projectRoleIdList, workTypeIdList, workProcessIdList)}>
                        検索
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.content}>
                <Paper>
                    <Autocomplete
                        className={classes.autoComplete}
                        multiple
                        options={masterData.techList}
                        getOptionLabel={(option) => option.techName}
                        value={techList}
                        onChange={(e, value) => handleTechChange(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="技術"
                            />
                        )}
                    />
                </Paper>
                <Paper >
                    <FormControl>
                        <div className="line">
                            <FormLabel>開発手法</FormLabel>
                        </div>
                        <FormGroup row>
                            {masterData.developmentMethodList.map(developmentMethod => (
                                <FormControlLabel
                                    key={developmentMethod.developmentMethodId}
                                    label={developmentMethod.developmentMethodName}
                                    control={
                                        <Checkbox
                                            checked={developmentMethodIdList.includes(developmentMethod.developmentMethodId)}
                                            onChange={(e, checked) => handleDevelopmentMethodChange(developmentMethod.developmentMethodId, checked)}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Paper>
                <Paper >
                    <FormControl>
                        <div className="line">
                            <FormLabel>役割</FormLabel>
                        </div>
                        <FormGroup row>
                            {masterData.projectRoleList.map(projectRole => (
                                <FormControlLabel
                                    key={projectRole.projectRoleId}
                                    label={projectRole.projectRoleName}
                                    control={
                                        <Checkbox
                                            checked={projectRoleIdList.includes(projectRole.projectRoleId)}
                                            onChange={(e, checked) => handleProjectRoleChange(projectRole.projectRoleId, checked)}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Paper>
                <Paper >
                    <FormControl>
                        <div className="line">
                            <FormLabel>作業種別</FormLabel>
                        </div>
                        <FormGroup row>
                            {masterData.workTypeList.map(workType => (
                                <FormControlLabel
                                    key={workType.workTypeId}
                                    label={workType.workTypeName}
                                    control={
                                        <Checkbox
                                            checked={workTypeIdList.includes(workType.workTypeId)}
                                            onChange={(e, checked) => handleWorkTypeChange(workType.workTypeId, checked)}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Paper>
                <Paper >
                    <FormControl>
                        <div className="line">
                            <FormLabel>作業工程</FormLabel>
                        </div>
                        <FormGroup row>
                            {masterData.workProcessList.map(workProcess => (
                                <FormControlLabel
                                    key={workProcess.workProcessId}
                                    label={workProcess.workProcessName}
                                    control={
                                        <Checkbox
                                            checked={workProcessIdList.includes(workProcess.workProcessId)}
                                            onChange={(e, checked) => handleWorkProcessChange(workProcess.workProcessId, checked)}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Paper>
            </DialogContent>
        </Dialog>
    );
}