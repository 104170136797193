import React, { Component, Fragment } from 'react';
import 'handsontable/dist/handsontable.full.min.css'
import { Tab } from '@material-ui/core';
import techMaintenanceService from '../../Service/TechMaintenanceService';
import MaintenanceLayout from '../Common/MaintenanceLayout';
import { Loading } from '../Loading/Loading';
import { TechGroupTable } from './Parts/TechGroupTable';
import { TechTable } from './Parts/TechTable';
import { TabPanel, TabList, TabContext } from '@material-ui/lab';
import SaveFab from '../Common/SaveFab';
import { YesNoDialog } from '../Common/Dialogs';
import utility from '../../Utility';



const TabMode = {
    TECHGROUP: 'tech-group',
    TECH: 'tech'
}


export class TechMaintenance extends Component {
    constructor(props) {
        super(props);
        this.index = utility.createIndex();
        this.state = {
            loading: true,
            dialog: false,
            genre: 1,
            data: {
                techGenreList: []
            },
            tabMode: TabMode.TECHGROUP
        };
    }

    async componentDidMount() {
        const data = await techMaintenanceService.getMaster();
        this.setState({
            loading: false,
            data: data
        });
    }

    async handleSaveYesButton() {
        this.setState({
            loading: true,
            dialog: false
        });
        const techGenreList = this.state.data.techGenreList.map((techGenre) => {

            //filterで技術グループ名が空の行を削除する
            const techGroupList = techGenre.techGroupList.filter(x => x.techGroupName).map((techGroup) => {

                //filterでtechGenre直下のtechListを持ってくる、また技術名が空の行を削除する
                const techList = techGenre.techList.filter(x => x.techGroupId === techGroup.techGroupId && x.techName).map((tech) => {
                    const TechAndTechVersionList = tech.techAndTechVersionList.map((techVersion) => {
                        const techVersionId = typeof techVersion.techVersionId === 'string' ? 0 : techVersion.techVersionId;
                        return ({
                            techVersionId,
                            TechVersionSortOrdrer: techVersion.techVersionSortOrdrer,
                            techVersionName: techVersion.techVersionName,
                            isRegister: !techVersionId
                        });
                    })
                    const techId = typeof tech.techId === 'string' ? 0 : tech.techId;
                    return ({
                        techId,
                        sortOrder: tech.sortOrder,
                        techName: tech.techName,
                        TechAndTechVersionList,
                        isRegister: !techId
                    });
                });
                const techGroupId = typeof techGroup.techGroupId === 'string' ? 0 : techGroup.techGroupId;
                return ({
                    techGroupId,
                    sortOrder: techGroup.sortOrder,
                    techGroupName: techGroup.techGroupName,
                    techList,
                    isRegister: !techGroupId
                });
            })
            return ({
                techGenreId: techGenre.techGenreId,
                techGroupList
            });
        });
        await techMaintenanceService.saveTechInfo(techGenreList);
        const data = await techMaintenanceService.getMaster();
        this.setState({
            loading: false,
            data: data
        });
    }

    /**
     * 技術マスタデータをソートします。
     * 
     * @param {any} techGenreId
     * @param {any} data
     */
    sortData(techGenreId, data) {
        const techGroupList = data.techGenreList.filter(tg => tg.techGenreId === techGenreId)[0].techGroupList;
        const techList = data.techGenreList.filter(tg => tg.techGenreId === techGenreId)[0].techList;
        techGroupList.sort(function (a, b) {
            return a.sortOrder - b.sortOrder;
        });
        techList.sort(function (a, b) {
            return a.sortOrder - b.sortOrder;
        });
    }

    renderContents() {
        this.sortData(this.state.genre, this.state.data)
        const menulist = this.state.data.techGenreList.map((tg) => {
            return {
                key: tg.techGenreId,
                body: (<div>{tg.techGenreName}</div>),
                onClick: () => this.setState({ genre: tg.techGenreId })
            }
        });

        return (
            <div>
                <MaintenanceLayout menulist={menulist} value={this.state.genre} >
                    <TabContext value={this.state.tabMode}>
                        <TabList
                            onChange={(event, newValue) => this.setState({ tabMode: newValue })}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label='技術グループ' value={TabMode.TECHGROUP} />
                            <Tab label='技術' value={TabMode.TECH} />
                        </TabList>
                        <TabPanel value={TabMode.TECHGROUP} >
                            <TechGroupTable data={this.state.data} genre={this.state.genre} index={this.index} />
                        </TabPanel>
                        <TabPanel value={TabMode.TECH}>
                            <TechTable data={this.state.data} genre={this.state.genre} index={this.index} />
                        </TabPanel>
                    </TabContext>
                </MaintenanceLayout>
            </div>
        );
    }

    render() {
        const contents = this.state.loading ? <Loading loading={this.state.loading} /> : this.renderContents();

        return (
            <Fragment>
                {contents}
                <SaveFab onClick={() => this.setState({ dialog: true })} />
                <YesNoDialog open={this.state.dialog} message='保存しますか？' onClickYes={() => { this.handleSaveYesButton() }} onClickNo={() => this.setState({ dialog: false })} />
            </Fragment>
        );
    }
}


