import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        const hasRole = this.hasRole(this.props.role);
        var link = document.createElement("a");
        link.href = this.props.path;

        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`
        const logoutRedirectUrl = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        if (hasRole) {
                            return <Component {...props} />
                        } else {
                            return <Redirect to={logoutRedirectUrl} />
                        }
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    hasRole(val) {
        if (!val) return true;
        if (!this.state.role || !this.state.role.operationContentCode) return false;
        const codes = this.state.role.operationContentCode.slice();
        return codes.some(x => x === val);
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const role = await authService.getUserRole();
        this.setState({ ready: true, authenticated, role });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
