import React from "react";
import { Table } from "reactstrap";
import { TableBody, TableCell, TableRow, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import { Container, Draggable } from 'react-smooth-dnd';
import utility from "../../../Utility";

const MAX_EXCEL_OUTPUT = 11;

const useStyles = makeStyles((theme) => ({
    table: {
        '& .tech-genre': {
            minWidth: "150px"
        },
        '& .tech-summary': {
            display: 'flex'
        },

        '& .draggable': {
            '& .MuiCard-root': {
                cursor: 'grab !important',

                '&.dragging': {
                    cursor: 'grabbing !important',
                    backgroundColor: "#e3f2fd",
                },
                '&:hover': {
                    backgroundColor: "#e3f2fd",
                },
            },
        },

        '& .MuiCard-root': {
            minWidth: "150px",
            margin: "5px",
            userSelect: 'none',

            '& .card-content': {
                paddingBottom: "16px",

                '& .typography': {
                    whiteSpace: "nowrap"
                }
            },
            '&.out-of-range': {
                backgroundColor: "grey",
            },
        }
    }
}));

export function TechSummaryTable(props) {
    const classes = useStyles();
    const techGenreList = props.techGenreList;
    const techSummaryList = props.techSummaryList;

    return (
        <Table className={classes.table}>
            <TableBody>
                {techGenreList.map((tg) => {
                    return (
                        <TableRow key={tg.techGenreId}>
                            <TableCell className="tech-genre">{tg.techGenreName}</TableCell>
                            <TechTableCell editable={props.editable} techGenre={tg} techSummaryList={techSummaryList} />
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

function TechTableCell(props) {
    if (!props.editable) {
        return (
            <TableCell className="tech-summary">
                {props.techSummaryList.filter(ts => ts.techGenreId === props.techGenre.techGenreId).map((ts, index) => {
                    return (
                        <TechCard index={index} key={ts.techId} techSummary={ts} />
                    );
                })}
            </TableCell>
        );
    }

    return (
        <Container
            orientation="horizontal"
            dragClass="dragging"
            render={(ref) => {
                return (
                    <TableCell ref={ref} className="tech-summary">
                        {props.techSummaryList.filter(ts => ts.techGenreId === props.techGenre.techGenreId).map((ts, index) => {
                            return (
                                <Draggable className="draggable" key={ts.techId}>
                                    <TechCard index={index} techSummary={ts} />
                                </Draggable>
                            );
                        })}
                    </TableCell>
                );
            }}
        />
    );
}

function TechCard(props) {
    const className = props.index + 1 > MAX_EXCEL_OUTPUT ? "out-of-range" : "";
    return (
        <Card className={className}>
            <CardContent className="card-content">
                <Typography className="typography">{props.techSummary.techName}</Typography>
                <Typography className="typography">{utility.convertFromMonthToYearMonth(props.techSummary.experienceMonth)}</Typography>
            </CardContent>
        </Card>
    );
}