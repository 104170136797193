import React from 'react';
import { makeStyles, Fab } from "@material-ui/core";
import { Save } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: "2rem",
        right: "2rem",
        '& .MuiFab-root': {
            marginLeft: "1em",
        },
    }
}));

export default function SaveFab(props) {
    const classes = useStyles();
    return (
        <div className={classes.fab}>
            <Fab type="submit" color="primary" {...props}>
                <Save />
            </Fab>
        </div>
    );
}