import React, { Fragment } from 'react';
import moment from 'moment';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Select, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@material-ui/core";
import SaveFab from '../../Common/SaveFab';
import userMaintenanceService from '../../../Service/UserMaintenanceService';
import { YesNoDialog } from '../../Common/Dialogs';
import { Delete, AddBox, DeleteForever } from '@material-ui/icons';

export default function UserRole(props) {
    const appRoleList = props.data.appRoleMaster.appRoleList.slice();
    const [state, setState] = React.useState({
        data: props.data.userList.slice(),
        newUser: []
    });

    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [openAddUserFormDialog, setOpenAddUserFormDialog] = React.useState(false);

    return (
        <Fragment>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '50px' }}></TableCell>
                            <TableCell style={{ width: '200px' }}>id</TableCell>
                            <TableCell style={{ width: '150px' }}>名前</TableCell>
                            <TableCell style={{ width: '200px' }}>最終ログイン日時</TableCell>
                            <TableCell style={{ width: '200px' }}>権限</TableCell>
                            <TableCell style={{ width: '50px' }}>
                                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
                                    setOpenAddUserFormDialog(true);
                                }}>
                                    <AddBox />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.data.map(user => {
                            const name = user.userProfileModel.familyName && user.userProfileModel.firstName ?
                                user.userProfileModel.familyName + ' ' + user.userProfileModel.firstName : '(noname)';
                            return (<TableRow key={user.userName}>
                                <TableCell>
                                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
                                        setState((prevState) => {
                                            // 削除フラグを設定する
                                            let data = [...prevState.data];
                                            const index = data.findIndex(x => x.userName === user.userName);
                                            data[index].isUpdate = true;
                                            data[index].isDelete = !data[index].isDelete;

                                            // 保存前に追加したユーザーは配列から削除する
                                            let newUser = [...prevState.newUser];
                                            if (newUser.findIndex(x => x === user.userName) !== -1) {
                                                newUser = newUser.filter(x => x !== user.userName);
                                                data = data.filter(x => x.userName !== user.userName);
                                            }
                                            return { ...prevState, data, newUser };
                                        });
                                    }}>
                                        {user.isDelete ? <DeleteForever /> : <Delete />}
                                    </IconButton>
                                </TableCell>
                                <TableCell>{user.userName}</TableCell>
                                <TableCell>{name}</TableCell>
                                <TableCell>{user.lastLoginDateTime ? moment(user.lastLoginDateTime).format("YYYY-MM-DD HH:mm:ss") : ''}</TableCell>
                                <TableCell>
                                    <Select disabled={user.isDelete} value={user.appRoleId} style={{ flex: '1', width: '180px' }} onChange={(e) => {
                                        setState((prevState) => {
                                            const value = e.target.value;
                                            const data = [...prevState.data];
                                            const index = data.findIndex(x => x.userName === user.userName);
                                            data[index].appRoleId = value;
                                            data[index].isUpdate = true;
                                            return { ...prevState, data };
                                        });
                                    }}>
                                        <MenuItem value={0}>未設定</MenuItem>
                                        {appRoleList.map(appRole => {
                                            return (<MenuItem key={appRole.appRoleId} value={appRole.appRoleId}>{appRole.appRoleName}</MenuItem>);
                                        })}
                                    </Select>
                                </TableCell>
                                <TableCell>{user.isUpdate ? (<span style={{ color: 'red' }}>*</span>) : <span />}</TableCell>
                            </TableRow>);
                        })}
                    </TableBody>
                </Table>
            </TableContainer >
            <SaveFab onClick={() => {
                setOpenConfirmDialog(true);
            }} />
            <YesNoDialog
                open={openConfirmDialog}
                message="保存しますか？"
                onClickYes={() => {
                    saveUserRole(state.data).then(data => {
                        setState({
                            data,
                            newUser: []
                        });
                        setOpenConfirmDialog(false);
                    });
                }}
                onClickNo={() => {
                    setOpenConfirmDialog(false);
                }} />
            <AddUserFormDialog open={openAddUserFormDialog} onClick={(userName) => {
                if (userName && state.data.findIndex(x => x.userName === userName) === -1) {
                    // ユーザーを追加する
                    setState((prevState) => {
                        const data = [...prevState.data];
                        const newUser = [...prevState.newUser];
                        newUser.push(userName);
                        data.push({
                            userName,
                            isUpdate: true,
                            userProfileModel: {}
                        });
                        return { ...prevState, data, newUser };
                    });
                }
                setOpenAddUserFormDialog(false);
            }} />
        </Fragment>
    );
}

function AddUserFormDialog(props) {
    const [userName, setUserName] = React.useState("");

    return (
        <Dialog open={props.open} onEnter={() => { setUserName(""); }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">ユーザ権限登録</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    事前に権限設定が必要なユーザー情報を入力してください。
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={userName}
                    onChange={(e) => { setUserName(e.target.value); }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClick(userName); }} color="primary">追加</Button>
                <Button onClick={() => { props.onClick(); }} color="primary">キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
}

async function saveUserRole(data) {
    await userMaintenanceService.saveUserRole(data);
    const newData = await userMaintenanceService.getUserMaintenanceInfo();
    return newData.userList;
}