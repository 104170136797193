import ServiceCommon from "./ServiceCommon";

class ProfileService extends ServiceCommon {
    async getProfile(userName) {
        const response = await this.get('/api/Profile/GetProfile', { userName });
        return response;
    }

    async saveProfile(saveData) {
        const response = await this.post('/api/Profile/SaveProfile', saveData)
        return response;
    }

    async uploadFile(userName, files) {
        const response = await this.postFormData(`/api/Profile/UploadAttachment/?handler=Upload&userName=${userName ? userName : ''}`, files);
        return response;
    }

    async deleteFile(userName, fileName) {
        const response = await this.get('/api/Profile/DeleteAttachment', { userName, fileName })
        return response;
    }

    async downloadAttachment(userName, fileName) {
        this.download('/api/Profile/DownloadAttachment', { userName, fileName });
    }
}

const profileService = new ProfileService();
export default profileService;
