import React from "react";
import { Dialog, Button } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

/**
 * YesNoダイアログ
 * 
 * @param {any} props
 */
export function YesNoDialog(props) {
    const classes = makeStyles({
        root: {
            '& .MuiPaper-root': {
                width: '400px',
                height: '200px'
            }
        }
    })();

    return (
        <Dialog open={props.open} aria-labelledby="customized-dialog-title" className={classes.root}>
            <DialogTitle id="customized-dialog-title" onClose={props.onClickNo}>
                <HelpOutlineIcon />確認
            </DialogTitle>
            <DialogContent dividers>
                {props.message}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClickYes} color="primary">はい</Button>
                <Button onClick={props.onClickNo} color="primary">いいえ</Button>
            </DialogActions>
        </Dialog>
    );
}
