import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './MenuStyles';
import { LoginMenu } from './LoginMenu';

export default function MenuAppBar(props) {
    const classes = useStyles();
    const open = props.open;

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.onClick}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title} noWrap>
                    <img src={`${process.env.PUBLIC_URL}/favicon.png`} />
                    career notes
                </Typography>
                <LoginMenu auth={true} />
            </Toolbar>
        </AppBar>
    );
}
