import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import careerService from "../../../Service/CareerService";
import { ExcelFormatType } from "../../../Service/CareerService";
import { GetApp } from '@material-ui/icons';

const ITEM_HEIGHT = 48;

export function EtcMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (excelFormatType) => {
        if (excelFormatType) {
            careerService.excelDownload(props.userName, excelFormatType);
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <GetApp />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={()=>handleClose()}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {ExcelFormatType.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleClose(option) }>
                        {option.display}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
