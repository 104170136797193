import React, { Component } from 'react';
import userMaintenanceService from '../../Service/UserMaintenanceService';
import MaintenanceLayout from '../Common/MaintenanceLayout';
import UserRole from './Parts/UserRole';
import AppRole from './Parts/AppRole';
import { Loading } from '../Loading/Loading';

const MenuMode = {
    NONE: 0,
    UserRole: 1,
    AppRole: 2
};

export class UserMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuMode: MenuMode.NONE,
            loading: true
        };
    }

    componentDidMount() {
        this.initMenu(MenuMode.UserRole);
    }

    initMenu = (menuMode) => {
        if (this.state.menuMode === menuMode) return;
        userMaintenanceService.getUserMaintenanceInfo().then(data => {
            this.setState({
                menuMode: menuMode,
                loading: false,
                data
            });
        });
    }

    renderContents() {
        const menulist = [
            {
                key: MenuMode.UserRole,
                body: (<div>ユーザー権限付与</div>),
                onClick: this.initMenu
            },
            {
                key: MenuMode.AppRole,
                body: (<div>権限編集</div>),
                onClick: this.initMenu
            }
        ];

        return (
            <MaintenanceLayout menulist={menulist} value={this.state.menuMode}>
                {
                    this.state.menuMode === MenuMode.UserRole ?
                        <UserRole data={this.state.data} /> :
                        <AppRole data={this.state.data} />
                }
            </MaintenanceLayout>
        );
    }

    render() {
        const contents = this.state.loading ? (<Loading loading={this.state.loading} />) : this.renderContents();
        return contents;
    }
}
