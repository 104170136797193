import React, { Component } from 'react';
import MaintenanceLayout from '../Common/MaintenanceLayout';
import MasterTable from './Parts/MasterTable';
import masterMaintenanceService from '../../Service/MasterMaintenanceService';
import { YesNoDialog } from '../Common/Dialogs';
import { Loading } from '../Loading/Loading';

const MenuMode = {
    NONE: 0,
    CERTIFICATION: 1,
    WORK_PROCESS: 3,
    DEVELOPMENT_METHOD: 4,
    PROJECT_ROLE: 5,
    WORK_TYPE: 6
};

export class MasterMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuMode: MenuMode.NONE,
            loading: true,
            dialog: false
        };
    }

    componentDidMount() {
        this.initMenu(MenuMode.CERTIFICATION);
    }

    initMenu = (menuMode) => {
        if (this.state.menuMode === menuMode) return;
        masterMaintenanceService.getMaster().then(data => {
            this.setState({
                menuMode: menuMode,
                loading: false,
                data
            });
        });
    }

    handleSavebutton(result) {
        this.result = result;
        this.setState({ dialog: true });
    }

    handleSaveYesButton() {
        this.setState({
            dialog: false,
            loading: true
        });
        const saveData = this.result;

        let setData;
        switch (this.state.menuMode) {
            case MenuMode.CERTIFICATION:
                setData = () => masterMaintenanceService.saveCertification(saveData);
                break;
            case MenuMode.WORK_PROCESS:
                setData = () => masterMaintenanceService.saveWorkProcess(saveData);
                break;
            case MenuMode.DEVELOPMENT_METHOD:
                setData = () => masterMaintenanceService.saveDevelopmentMethod(saveData);
                break;
            case MenuMode.PROJECT_ROLE:
                setData = () => masterMaintenanceService.saveProjectRole(saveData);
                break;
            case MenuMode.WORK_TYPE:
                setData = () => masterMaintenanceService.saveWorkType(saveData);
                break;
            default:
                break;
        }


        setData().then(() =>
            masterMaintenanceService.getMaster().then(data => {
                this.setState({
                    loading: false,
                    data
                });
            })
        );
    }

    handleSaveNobutton() {
        this.setState({ dialog: false });
    }

    renderContents() {
        const menulist = [
            {
                key: MenuMode.CERTIFICATION,
                body: (<div>資格</div>),
                onClick: this.initMenu
            },
            {
                key: MenuMode.WORK_PROCESS,
                body: (<div>作業工程</div>),
                onClick: this.initMenu
            },
            {
                key: MenuMode.DEVELOPMENT_METHOD,
                body: (<div>開発手法</div>),
                onClick: this.initMenu
            },
            {
                key: MenuMode.PROJECT_ROLE,
                body: (<div>役割</div>),
                onClick: this.initMenu
            },
            {
                key: MenuMode.WORK_TYPE,
                body: (<div>作業種別</div>),
                onClick: this.initMenu
            }
        ];

        let contents = (<div />);
        switch (this.state.menuMode) {
            case MenuMode.CERTIFICATION:
                contents = (<div>
                    <MasterTable data={this.state.data.certificationList} onClick={(result) => this.handleSavebutton(result)} colHeaders={['ID', '資格名']} columnIdData='certificationId' columnNameData='certificationName' />
                </div>);
                break;
            case MenuMode.WORK_PROCESS:
                contents = (<div>
                    <MasterTable data={this.state.data.workProcessList} onClick={(result) => this.handleSavebutton(result)} colHeaders={['ID', '作業工程名']} contextMenu={false} columnIdData='workProcessId' columnNameData='workProcessName' />
                </div>);
                break;
            case MenuMode.DEVELOPMENT_METHOD:
                contents = (<div>
                    <MasterTable data={this.state.data.developmentMethodList} onClick={(result) => this.handleSavebutton(result)} colHeaders={['ID', '開発手法名']} columnIdData='developmentMethodId' columnNameData='developmentMethodName' />
                </div>);
                break;
            case MenuMode.PROJECT_ROLE:
                contents = (<div>
                    <MasterTable data={this.state.data.projectRoleList} onClick={(result) => this.handleSavebutton(result)} colHeaders={['ID', '役割名']} columnIdData='projectRoleId' columnNameData='projectRoleName' />
                </div>);
                break;
            case MenuMode.WORK_TYPE:
                contents = (<div>
                    <MasterTable data={this.state.data.workTypeList} onClick={(result) => this.handleSavebutton(result)} colHeaders={['ID', '作業種別名']} columnIdData='workTypeId' columnNameData='workTypeName' />
                </div>);
                break;
            default:
                break;
        }

        return (
            <div>
                <MaintenanceLayout menulist={menulist} value={this.state.menuMode}>
                    {contents}
                </MaintenanceLayout>
                <YesNoDialog
                    open={this.state.dialog}
                    message="保存しますか"
                    onClickYes={() => this.handleSaveYesButton()}
                    onClickNo={() => this.handleSaveNobutton()}
                />
            </div>
        );
    }

    render() {
        const contents = this.state.loading ? (<Loading loading={this.state.loading} />) : this.renderContents();
        return contents;
    }
}
