import React from 'react';
import { Select, MenuItem, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { SummaryGroupBarChart } from './SummaryGroupChart';
import { Fragment } from 'react';

export function TechAnalysis(props) {
    const techGenreList = props.techGenreList;
    const [techGenreId, setTechGenreId] = React.useState(techGenreList[0].techGenreId);
    const techGenre = techGenreList.filter(x => x.techGenreId === techGenreId)[0];
    const techList = techGenre.techList.filter(t => t.summaryGroup.totalCount > 0);
    techList.sort(function (a, b) {
        const result = b.summaryGroup.totalCount - a.summaryGroup.totalCount;
        return result === 0 ? a.sortOrder - b.sortOrder : result;
    });

    const techSummaryGroup = techList.length === 0 ? [] : techList[0].summaryGroup;

    return (<div>
        <Select style={{ flex: '1', width: '180px' }} value={techGenreId} onChange={(e) => {
            const value = parseInt(e.target.value);
            setTechGenreId(value);
        }}>
            {techGenreList.map(tg => {
                return (<MenuItem key={tg.techGenreId} value={tg.techGenreId}>{tg.techGenreName}</MenuItem>);
            })}
        </Select>
        {techList.length > 0 &&
            <Fragment>
                <SummaryGroupBarChart title="グループ" backgroundColorPaletteName={["cb-YlGnBu"]} data={techGenre.techGroupList} />

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>グループ</TableCell>
                            <TableCell>技術名</TableCell>
                            <TableCell align="right" style={{ width: "100px" }}>人数</TableCell>
                            {techSummaryGroup.summaryGroupDetailList.map(t => {
                                return (<TableCell key={t.id} align="right" style={{ width: "100px" }}>{t.description}</TableCell>);
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {techList.map((row, index) => (
                            <TableRow key={row.techId}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell>{getGroupName(techGenre.techGroupList, row.techGroupId)}</TableCell>
                                <TableCell>{row.techName}</TableCell>
                                <TableCell align="right">{row.summaryGroup.totalCount}</TableCell>
                                {row.summaryGroup.summaryGroupDetailList.map(t => {
                                    return (<TableCell key={t.id} align="right">{t.count}</TableCell>);
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Fragment>
        }
    </div>);
}

/**
 * グループ名を取得
 * @param {any} techGroupList
 * @param {any} techGroupId
 */
function getGroupName(techGroupList, techGroupId) {
    return techGroupList.filter(t => t.techGroupId === techGroupId)[0].techGroupName;
}