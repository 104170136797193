import authService from "../components/api-authorization/AuthorizeService";

export default class ServiceCommon {
    /**
     * GET
     * 
     * @param {any} url
     */
    async get(url, param, appendParam) {
        const token = await authService.getAccessToken();
        let requestInit = {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }
        if (appendParam) {
            requestInit = Object.assign(requestInit, appendParam);
        }
        url = createSearchURL(url, param);
        const response = await fetch(url, requestInit);
        return response;
    }

    /**
     * POST
     * 
     * @param {any} url
     * @param {any} param
     */
    async post(url, param) {
        const method = "POST";
        const body = JSON.stringify(param);
        const token = await authService.getAccessToken();
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const response = await fetch(url, { method, headers, body });
        return response;
    }

    /**
     * ファイルダウンロード
     * 
     * @param {any} url
     */
    async download(url, param) {
        const response = await this.get(url, param, { responseType: 'blob' });
        if (response.status === 200) {
            const blob = await response.blob();
            const fileName = getFileName(response.headers.get('content-disposition'));
            const contentType = response.headers.get('content-type');
            downloadFile(blob, fileName, contentType)
        } else {
            console.log('download fail.');
        }
    }

    /**
     * ファイルアップロード
     * @param {any} oFormElement
     */
    async postFormData(url,files) {
        const method = "POST";
        const body = new FormData();
        body.append("files",files)
        const token = await authService.getAccessToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        };
        const response = await fetch(url, { method, headers, body });
        return response;
    }
}

/**
 * URLを作成する。
 * 
 * @param {any} url
 * @param {any} params
 */
export function createSearchURL(url, params) {
    const newParams = [];
    if (params) {
        Object.getOwnPropertyNames(params).map(x => {
            if (x && params[x]) {
                newParams.push([x, params[x]]);
            }
        });
    }

    if (newParams.length === 0) {
        return url;
    }

    const urlParams = new URLSearchParams(newParams);
    return `${url}?${urlParams.toString()}`
}

/**
 * URLを作成する。(base64)
 * 
 * @param {any} url
 * @param {any} params
 */
export function createSearchURLBase64(url, params) {
    const data = btoa(unescape(encodeURIComponent(JSON.stringify(params))));
    return createSearchURL(url, { data });
}

/**
 * URLからデータを取得する。(base64)
 * @param {any} url
 */
export function URLSearchParamsBase64(url) {
    const params = new URLSearchParams(url);
    const strData = params.get("data");
    if (!strData) {
        return {};
    }

    const data = decodeURIComponent(escape(atob(strData)));
    return JSON.parse(data);
}

/**
 * ファイルをダウンロードする
 * 
 * @param {any} blob
 * @param {any} fileName
 * @param {any} contentType
 */
function downloadFile(blob, fileName, contentType) {
    const url = URL.createObjectURL(new Blob([blob], { type: contentType }));
    const linkEl = document.createElement('a');
    linkEl.href = url;
    linkEl.setAttribute('download', fileName);
    document.body.appendChild(linkEl);
    linkEl.click();

    URL.revokeObjectURL(url);
    linkEl.parentNode.removeChild(linkEl);
}

/**
 * ヘッダ情報からファイル名を取得する
 * 
 * @param {any} disposition
 */
function getFileName(disposition) {
    // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const filenameRegex = /filename\*=UTF-8''((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
        const fileName = matches[1].replace(/['"]/g, '');
        return decodeURI(fileName);
    } else {
        return null
    }
}
