export const ApplicationName = 'SkillSheetApp';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register'
};

const prefix = '/authentication';

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: '/Identity/Account/Register',
    IdentityManagePath: '/Identity/Account/Manage'
};

export const OperationContentCode = {
    /// <summary>無効</summary>
    INVALID: 0,
    /// <summary>ホーム権限</summary>
    VIEW_HOME: 1,
    /// <summary>基本情報権限</summary>
    VIEW_USER_PROFILE: 2,
    /// <summary>経歴情報権限</summary>
    VIEW_CAREER: 3,
    /// <summary>経歴検索権限</summary>
    VIEW_SEARCH: 4,
    /// <summary>分析権限</summary>
    VIEW_ANALYSIS: 9,
    /// <summary>技術情報管理権限</summary>
    VIEW_TECH_MAINTENANCE: 5,
    /// <summary>マスター管理権限</summary>
    VIEW_MASTER_MAINTENANCE: 6,
    /// <summary>ユーザー管理権限</summary>
    VIEW_USER_MAINTENANCE: 7
};
