import React, { Fragment, useState } from "react";
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { TextField, RadioGroup, FormControlLabel, Radio, FormLabel, Fab, IconButton, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Save, ArrowBack, Search, Delete, ExpandMore, CheckCircle, CancelRounded, CachedRounded } from "@material-ui/icons";
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import utility from "../../../Utility";
import { YesNoDialog } from "../../Common/Dialogs";
import { SelectStationsDialog } from "./SelectStationsDialog";
import { Profile } from "../Profile";
import profileService from "../../../Service/ProfileService";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: "0px 10px 20px 0px",
        },
        '& .MuiSelect-select': {
            width: '130px'
        },
        '& .MuiChip-root': {
            margin: "0.5em 0.5em 0.5em 0em"
        },
        '& .line': {
            verticalAlign: 'middle',
            display: 'flex',
            alignItems: 'center',
            '& .help': {
                marginLeft: '2px',
                marginRight: '5px',
            }
        },
        '& .nearestStation': {
            maxWidth: "400px",
            width: '100%'
        }
    },
    fab: {
        position: 'fixed',
        bottom: "2rem",
        right: "2rem",
        zIndex: 1000,
        '& .MuiFab-root': {
            marginLeft: "1em",
        },
    },
    dropZone: {
        zIndex: 1
    }
}));

export function EditProfile(props) {
    const userProfile = props.data.userProfile;
    const fileInfoList = props.data.fileInfoList;
    const { control, handleSubmit, register, errors } = useForm();

    // 変更フラグ
    const [update, setUpdate] = useState(false);
    // 確認ダイアログ表示フラグ
    const [open, setOpen] = useState(false);

    const [nearestStation, setNearestStation] = useState(userProfile.nearestStation);
    const [openSelectStationsDialog, setOpenSelectStationsDialog] = React.useState(false);
    const [deleteFileName, setDeleteFileName] = useState('');

    const handleClose = (newValue) => {
        setOpenSelectStationsDialog(false);

        if (newValue) {
            setNearestStation(newValue);
            setUpdate(true);
        }
    };

    const deleteFile = (fileName) => {
        setDeleteFileName(fileName);
    };

    const certificationList = props.data.certificationList;
    const [holdCertificationList, setHoldCertificationList] = useState(userProfile.holdCertificationList);

    const onChange = () => {
        setUpdate(true);
    };
    const onChangeCertification = (e, value) => {
        setHoldCertificationList(value);
        setUpdate(true);
    };
    const onChangeNearestStation = (e) => {
        setNearestStation(e.target.value);
        setUpdate(true);
    };
    const classes = useStyles();

    const requiredMessage = "必須項目です";
    const defaultGender = userProfile.gender ? String(userProfile.gender) : "1";
    const genderList = [{ name: "男性", value: "1" }, { name: "女性", value: "2" }];
    const defaultBirthday = userProfile.birthday ? moment(userProfile.birthday).format("YYYY-MM-DD") : null;

    return (
        <Fragment>
            <div style={{ marginBottom: '10em' }}>
                <form className={classes.root} onSubmit={handleSubmit((data) => props.handleSaveButton(data, holdCertificationList))}>
                    <div>
                        <TextField
                            name="familyName"
                            label="姓"
                            required
                            inputRef={register({
                                required: requiredMessage,
                                maxLength: {
                                    value: 20,
                                    message: "20文字以内で入力してください"
                                }
                            })}
                            error={Boolean(errors.familyName)}
                            helperText={errors.familyName && errors.familyName.message}
                            defaultValue={userProfile.familyName}
                            onChange={onChange}
                        />
                        <TextField
                            name="firstName"
                            label="名"
                            required
                            inputRef={register({
                                required: requiredMessage,
                                maxLength: {
                                    value: 20,
                                    message: "20文字以内で入力してください"
                                }
                            })}
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName && errors.firstName.message}
                            defaultValue={userProfile.firstName}
                            onChange={onChange}
                        />
                    </div>
                    <div>
                        <TextField
                            name="familyNameKana"
                            label="セイ"
                            required
                            inputRef={register({
                                required: requiredMessage,
                                maxLength: {
                                    value: 20,
                                    message: "20文字以内で入力してください"
                                },
                                pattern: {
                                    value: /^[ァ-ヶー]+$/,
                                    message: "全角カタカナで入力してください"
                                }
                            })}
                            error={Boolean(errors.familyNameKana)}
                            helperText={errors.familyNameKana && errors.familyNameKana.message}
                            defaultValue={userProfile.familyNameKana}
                            onChange={onChange}
                        />
                        <TextField
                            name="firstNameKana"
                            label="メイ"
                            required
                            inputRef={register({
                                required: requiredMessage,
                                maxLength: {
                                    value: 20,
                                    message: "20文字以内で入力してください"
                                },
                                pattern: {
                                    value: /^[ァ-ヶー]+$/,
                                    message: "全角カタカナで入力してください"
                                }
                            })}
                            error={Boolean(errors.firstNameKana)}
                            helperText={errors.firstNameKana && errors.firstNameKana.message}
                            defaultValue={userProfile.firstNameKana}
                            onChange={onChange}
                        />
                    </div>
                    <div>
                        <FormLabel required>性別</FormLabel>
                        <Controller
                            name="gender"
                            as={
                                <RadioGroup name="gender1" row>
                                    {genderList.map(item => (
                                        <FormControlLabel
                                            key={item.value}
                                            value={item.value}
                                            control={<Radio onChange={onChange} />}
                                            label={item.name}
                                        />
                                    ))}
                                </RadioGroup>
                            }
                            control={control}
                            defaultValue={defaultGender}
                        />
                    </div>
                    <div>
                        <TextField
                            name="birthday"
                            label="生年月日"
                            required
                            type="date"
                            inputProps={{ max: "9999-12-31" }}
                            inputRef={register({
                                required: requiredMessage
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={Boolean(errors.birthday)}
                            helperText={errors.birthday && errors.birthday.message}
                            defaultValue={defaultBirthday}
                            onChange={onChange}
                        />
                    </div>
                    <div className="line">
                        <IconButton color="primary" component="span" onClick={() => { setOpenSelectStationsDialog(true); }}>
                            <Search />
                        </IconButton>
                        <TextField
                            name="nearestStation"
                            className="nearestStation"
                            label="最寄り駅"
                            inputRef={register({
                                maxLength: {
                                    value: 20,
                                    message: "20文字以内で入力してください"
                                }
                            })}
                            fullWidth
                            disabled
                            error={Boolean(errors.nearestStation)}
                            helperText={errors.nearestStation && errors.nearestStation.message}
                            value={nearestStation}
                            onChange={onChangeNearestStation}
                        />
                    </div>
                    <TextField
                        name="finalEducation"
                        label="最終学歴"
                        inputRef={register({
                            maxLength: {
                                value: 40,
                                message: "40文字以内で入力してください"
                            }
                        })}
                        fullWidth
                        error={Boolean(errors.finalEducation)}
                        helperText={errors.finalEducation && errors.finalEducation.message}
                        defaultValue={userProfile.finalEducation}
                        onChange={onChange}
                    />
                    <TextField
                        name="summary"
                        label="自己PR・その他"
                        inputRef={register({
                            validate: {
                                max: value => utility.conuntHankakuZenkaku(value) <= 206 || '文字数上限を超えています'
                            }
                        })}
                        multiline
                        rows={3}
                        rowsMax={3}
                        variant='outlined'
                        fullWidth
                        error={Boolean(errors.summary)}
                        helperText={errors.summary && errors.summary.message}
                        defaultValue={userProfile.summary}
                        onChange={onChange}
                    />
                    <div>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={certificationList}
                            getOptionLabel={(option) => option.certificationName}
                            defaultValue={userProfile.holdCertificationList.map(value => {
                                const sameCertification = certificationList.filter(c => c.certificationId === value.certificationId);
                                return sameCertification[0]
                            })}
                            onChange={onChangeCertification}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="資格"
                                />
                            )}
                        />
                    </div>
                    <div>
                        <TextField
                            name="remarks"
                            label="補足"
                            inputRef={register()}
                            defaultValue={userProfile.remarks}
                            fullWidth
                        />
                    </div>
                    <div className={classes.fab}>
                        <Fab
                            onClick={() => {
                                if (update) {
                                    // 変更されていた場合
                                    setOpen(true);
                                } else {
                                    // 変更されていなかった場合
                                    props.handleCancelButton();
                                }
                            }}
                            disabled={props.cancelButtonDisable}>
                            <ArrowBack />
                        </Fab>
                        <Fab type="submit" color="primary">
                            <Save />
                        </Fab>
                    </div>
                </form>
                <div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography variant='h6'>添付ファイル</Typography>
                        </AccordionSummary>
                        <div className={classes.dropZone}>
                            <DropzoneAreaBase
                                dropzoneText="ファイルをドロップするかクリックして選択してください"
                                maxFileSize={props.data.maxFileSize}
                                filesLimit={1}
                                onAdd={async (newFiles) => {
                                    await profileService.uploadFile(props.userName, newFiles[0].file);
                                    props.getData();
                                }}
                                showAlerts={['error', 'info']}
                                getFileLimitExceedMessage={(filesLimit) => { return `一度に選択できるファイル数は${filesLimit}つのみです` }}
                                getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                                    return `添付ファイルのサイズが最大値(${formatSizeUnits(maxFileSize)})を超えています`
                                }}
                            />
                        </div>
                        {fileInfoList.length > 0 &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ファイル名</TableCell>
                                        <TableCell>ウイルススキャン</TableCell>
                                        <TableCell>最終更新日時</TableCell>
                                        <TableCell>サイズ</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fileInfoList.map(value => {
                                        let statusIcon;
                                        switch (value.avStatus) {
                                            case 'CLEAN':
                                                statusIcon = <CheckCircle fontSize='small' style={{ color: "green" }} />;
                                                break;
                                            case 'INFECTED':
                                                statusIcon = <CancelRounded fontSize='small' style={{ color: "red" }} />;
                                                break;
                                            default:
                                                statusIcon = <CachedRounded fontSize='small' style={{ color: "blue" }} />;
                                        }
                                        return (
                                            <TableRow key={value.fileName}>
                                                <TableCell>{value.fileName}</TableCell>
                                                <TableCell>{statusIcon}</TableCell>
                                                <TableCell>{value.lastModified}</TableCell>
                                                <TableCell>{formatSizeUnits(value.size)}</TableCell>
                                                <TableCell style={{ maxWidth: 50 }}>
                                                    <IconButton
                                                        onClick={() => deleteFile(value.fileName)}
                                                        size='small'
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        }
                    </Accordion>
                </div>
            </div>
            <SelectStationsDialog
                keepMounted
                open={openSelectStationsDialog}
                onClose={handleClose} />
            <YesNoDialog
                open={open}
                message='変更内容が破棄されますが、よろしいですか？'
                onClickYes={() => {
                    setOpen(false);
                    props.handleCancelButton();
                }}
                onClickNo={() => setOpen(false)} />
            <YesNoDialog
                open={Boolean(deleteFileName)}
                message={`${deleteFileName}を削除しますが、よろしいですか？`}
                onClickYes={async () => {
                    await profileService.deleteFile(props.userName, deleteFileName);
                    setDeleteFileName('');
                    props.getData();
                }}
                onClickNo={() => setDeleteFileName('')} />
        </Fragment>
    );
}

function formatSizeUnits(bytes) {
    if (bytes >= 1048576) { return (bytes / 1048576).toFixed(1) + " MB"; }
    else if (bytes >= 1024) { return (bytes / 1024).toFixed(1) + " KB"; }
    else { return bytes + " B" }
}
