import React, { Component } from 'react';
import analysisService from "../../Service/AnalysisService";
import { TechAnalysis } from './Parts/TechAnalysis';
import MaintenanceLayout from '../Common/MaintenanceLayout';
import { AgeAnalysis } from './Parts/AgeAnalysis';
import { ProjectAnalysis } from './Parts/ProjectAnalysis';

const MenuMode = {
    NONE: 0,
    Tech: 1,
    Age: 2,
    Project: 3
};

export class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuMode: MenuMode.NONE,
            loading: true,
        }
    }

    componentDidMount() {
        this.initMenu(MenuMode.Tech);
    }

    initMenu = (menuMode) => {
        if (this.state.menuMode === menuMode) return;
        analysisService.getAnalysisData().
            then((data) => {
                this.setState({
                    menuMode: menuMode,
                    data,
                    loading: false,
                });
            });
    }

    render() {
        if (this.state.loading) {
            return (<div />);
        }

        const menulist = [
            {
                key: MenuMode.Tech,
                body: (<div>技術</div>),
                onClick: this.initMenu
            },
            {
                key: MenuMode.Age,
                body: (<div>年齢・経験年数</div>),
                onClick: this.initMenu
            },
            {
                key: MenuMode.Project,
                body: (<div>案件</div>),
                onClick: this.initMenu
            }
        ];

        let contents = (<div />);
        switch (this.state.menuMode) {
            case MenuMode.Tech:
                contents = (<div>
                    <TechAnalysis techGenreList={this.state.data.techGenreList} />
                </div>);
                break;
            case MenuMode.Age:
                contents = (<div>
                    <AgeAnalysis ageSummaryGroup={this.state.data.ageSummaryGroup} experienceMonthSummaryGroup={this.state.data.experienceMonthSummaryGroup} />
                </div>);
                break;
            case MenuMode.Project:
                contents = (<div>
                    <ProjectAnalysis projectAnalysis={this.state.data.projectAnalysis} />
                </div>);
                break;
        }

        return (<div>
            <MaintenanceLayout menulist={menulist} value={this.state.menuMode}>
                {contents}
            </MaintenanceLayout>
        </div>);
    }
}