import { HotTable } from '@handsontable/react';
import { AppBar, Button, Dialog, DialogContent, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { Close, AddBoxSharp } from '@material-ui/icons';
import 'handsontable/dist/handsontable.full.min.css';
import React from 'react';
import './TechVersionTableDialog.css';


const useStyles = makeStyles(theme => ({
    button: {
        margin: '0 0 0 auto',
        display: 'block'
    },
    table: {
        '& .id-column': {
            textAlign: 'center',
            backgroundColor: '#ffffc1'
        },
        '& .name-column': {

        },
        '& .version-column': {

        }
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export function TechVersionTableDialog(props) {
    const classes = useStyles();
    const hotTableComponent = React.createRef();

    //技術のバージョンが１つもなかった場合空の要素を１つもった配列をversionListに代入する
    const versionList = props.techList.filter(x => x.techId === props.techId)[0].techAndTechVersionList.length === 0
        ? [{ techVersionId: props.index.nextIndexString() }]
        : props.techList.filter(x => x.techId === props.techId)[0].techAndTechVersionList.slice();

    return (
        <Dialog open={Boolean(props.techId)} fullWidth>
            <AppBar className={classes.appBar}>

                <Toolbar style={{ minHeight: 0 }}>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {props.techList.filter(x => x.techId === props.techId)[0].techName}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => {
                        const hotInstance = hotTableComponent.current.hotInstance;
                        const dataList = hotInstance.getData();
                        dataList.map((data, index) => {
                            const version = versionList.filter(x => x.techVersionId === data[0])[0];
                            version.techVersionSortOrdrer = index + 1;
                        });

                        const versionListWithoutBrankRow = versionList.filter(x => x.techVersionName).sort(function (a, b) {
                            return a.techVersionSortOrdrer - b.techVersionSortOrdrer;
                        });

                        props.techList.filter(x => x.techId === props.techId)[0].techAndTechVersionList = versionListWithoutBrankRow;
                        props.handleClose()
                    }}>
                        選択
                    </Button>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <IconButton
                    className={classes.button}
                    color='primary'
                    onClick={() => {
                        const hotInstance = hotTableComponent.current.hotInstance;
                        hotInstance.alter('insert_row', hotInstance.countRows());
                        hotInstance.selectCell(hotInstance.countRows() - 1, 1);
                    }}
                >
                    <AddBoxSharp />
                </IconButton>
                <HotTable
                    ref={hotTableComponent}
                    className={classes.table}
                    data={versionList}
                    rowHeaders={true}
                    colHeaders={['ID', 'バージョン名']}
                    fillHandle={false}
                    height={400}
                    manualRowMove={true}
                    contextMenu={{
                        items: {
                            'row_above': { name: '上に1行挿入' },
                            'row_below': { name: '下に1行挿入' },
                            "hsep2": "---------",
                            'undo': { name: '元に戻す' }
                        }
                    }}
                    columns={[
                        ///バージョンID
                        {
                            className: 'id-column',
                            readOnly: true,
                            width: "50",
                            data: (row, value) => {
                                if (!row.techVersionId) {
                                    row.techVersionId = props.index.nextIndexString();
                                }
                                return row.techVersionId;
                            }
                        },
                        /// バージョン名
                        {
                            className: 'name-column',
                            readOnly: false,
                            width: "300",
                            data: 'techVersionName'
                        },
                    ]}
                />
            </DialogContent>
        </Dialog >
    );
}