import React, { Fragment } from "react";
import moment from 'moment';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, Button } from "@material-ui/core";
import { Brightness1 } from "@material-ui/icons";
import { ExcelDownloadButton } from "./ExcelDownloadButton";

const useStyles = makeStyles({
    tableContainer: {
        overflow: "visible"
    },
    table: {
        minWidth: '1500px',
        maxHeight: "800px",
        '& .MuiTableCell-root': {
            border: "1px solid rgba(224, 224, 224, 1)",
            padding: "5px 10px 5px 10px"
        },
        '& .MuiTableCell-head': {
            fontWeight: "bold",
            backgroundColor: "#eaeff1",
        },
        '& .MuiTableHead-root .MuiTableCell-root': {
            top: "64px"
        },
        '& tbody:hover': {
            backgroundColor: "#e3f2fd",
            cursor: "pointer"
        }
    },
    firstTr: {
        '& .MuiTableCell-root': {
            borderTop: "1px solid black"
        }
    },
    button: {
        marginBottom: "1em",
        marginRight: "1em"
    }
});

export function ViewProjectTable(props) {

    const classes = useStyles();

    const masterData = props.data.masterModel;
    const projectList = props.data.projectList;

    return (
        <Fragment>
            <ExcelDownloadButton className={classes.button} userName={props.userName} />

            <span style={{ verticalAlign: "super", fontWeight: "bold", fontSize: "15px" }}>案件をクリックすると編集・削除モードに入ります</span>
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>期間</TableCell>
                            <TableCell colSpan={2}>PJ概要</TableCell>
                            {masterData.workProcessList.map(workProcess => (
                                <TableCell style={{ padding: "1px 0", width: "1em" }} key={workProcess.workProcessId}>
                                    <span style={{ writingMode: "vertical-rl" }}>{workProcess.workProcessName}</span>
                                </TableCell>
                            ))}
                            <TableCell align="center" colSpan={2}>開発環境</TableCell>
                        </TableRow>
                    </TableHead>
                    {projectList.map((project, index) => {
                        const projectMonth = ((moment(project.endMonth).year() - moment(project.startMonth).year()) * 12) + moment(project.endMonth).month() - moment(project.startMonth).month() + 1;
                        const strProjectYear = Math.floor(projectMonth / 12) ? Math.floor(projectMonth / 12) + "年" : "";
                        const strProjectMonth = projectMonth % 12 ? projectMonth % 12 + "ヵ月" : "";
                        return (
                            <Fragment key={index + 1}>
                                <TableBody onClick={() => props.handleEditButton(project.projectId)}>
                                    <TableRow className={classes.firstTr}>
                                        <TableCell
                                            rowSpan={8}
                                            align="center"
                                        >
                                            {index + 1}
                                        </TableCell>
                                        <TableCell rowSpan={2} style={{ width: "10em" }} align="center">
                                            {moment(project.startMonth).format("yyyy年MM月")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {project.wholeMemberCount && `全体${project.wholeMemberCount}名/`}チーム{project.teamMemberCount}名
                                    </TableCell>
                                        <TableCell>
                                            開発手法　{masterData.developmentMethodList.filter(d => d.developmentMethodId === project.developmentMethodId)[0]
                                                ? masterData.developmentMethodList.filter(d => d.developmentMethodId === project.developmentMethodId)[0].developmentMethodName
                                                : ""}
                                        </TableCell>
                                        {masterData.workProcessList.map(workProcess => (
                                            <TableCell rowSpan={8} style={{ padding: "1px 0", width: "1em", fontSize: "2em" }}
                                                key={workProcess.workProcessId}
                                                align="center"
                                            >
                                                {project.workProcessIdList.includes(workProcess.workProcessId) && <Brightness1 />}
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ width: "80px" }}>
                                            OS
                                        </TableCell>
                                        <TableCell style={{ width: "400px" }}>
                                            <TechUsedList techGenreId={1} techAndTechVersionList={masterData.techAndTechVersionList} techUsed={project.techUsed} />
                                        </TableCell>
                                    </TableRow >
                                    <TableRow style={{ height: "30px" }}>
                                        <TableCell rowSpan={3} colSpan={2}>
                                            {project.projectOverview}
                                        </TableCell>
                                        <TableCell rowSpan={2}>
                                            開発言語
                                </TableCell>
                                        <TableCell rowSpan={2}>
                                            <TechUsedList techGenreId={2} techAndTechVersionList={masterData.techAndTechVersionList} techUsed={project.techUsed} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            ～
                                </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={2} align="center">
                                            {moment(project.endMonth).format("yyyy年MM月")}
                                        </TableCell>
                                        <TableCell>
                                            FW
                                </TableCell>
                                        <TableCell>
                                            <TechUsedList techGenreId={3} techAndTechVersionList={masterData.techAndTechVersionList} techUsed={project.techUsed} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            役割　{masterData.projectRoleList.filter(d => d.projectRoleId === project.projectRoleId)[0].projectRoleName}
                                        </TableCell>
                                        <TableCell>
                                            作業種別　{masterData.workTypeList.filter(d => d.workTypeId === project.workTypeId)[0].workTypeName}
                                        </TableCell>
                                        <TableCell>
                                            DB
                                </TableCell>
                                        <TableCell>
                                            <TechUsedList techGenreId={4} techAndTechVersionList={masterData.techAndTechVersionList} techUsed={project.techUsed} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={3} align="center">
                                            {strProjectYear + strProjectMonth}
                                        </TableCell>
                                        <TableCell rowSpan={3} colSpan={2}>
                                            {project.workContent}
                                        </TableCell>
                                        <TableCell>
                                            MW
                                </TableCell>
                                        <TableCell>
                                            <TechUsedList techGenreId={5} techAndTechVersionList={masterData.techAndTechVersionList} techUsed={project.techUsed} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={2}>
                                            ツール<br />PKG
                                    </TableCell>
                                        <TableCell rowSpan={2}>
                                            <TechUsedList techGenreId={6} techAndTechVersionList={masterData.techAndTechVersionList} techUsed={project.techUsed} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow></TableRow>
                                </TableBody>
                            </Fragment>
                        )
                    })}
                </Table>
            </TableContainer >
        </Fragment>
    );
}

/**
 * 開発環境一覧の出力
 * 
 * @param {any} props
 */
function TechUsedList(props) {
    const techGenreId = props.techGenreId;
    const techAndTechVersionList = props.techAndTechVersionList;
    const techUsed = props.techUsed;

    return (
        <Fragment>
            {techUsed.filter(tu => tu.techGenreId === techGenreId).map((t, i) => {
                const tech = techAndTechVersionList.filter(tv => tv.techId === t.techId && (tv.techVersionId === t.techVersionId || (!tv.hasTechVersion && !t.hasTechVersion)))[0];
                if (tech) {
                    return (
                        <Fragment key={i}>
                            <span className="techItem">{tech.description}　</span>
                        </Fragment>
                    );
                }
            })}
        </Fragment>
    );
}