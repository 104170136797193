import ServiceCommon from "./ServiceCommon";

class MasterMaintenanceService extends ServiceCommon {
    async getMaster() {
        const response = await this.get('/api/MasterMaintenance/getMaster');
        if (response.status === 200) {
            return await response.json();
        }
        return null;
    }

    async saveCertification(data) {
        const response = await this.post('/api/MasterMaintenance/RegisterCertification', data);
        return response;
    }

    async saveWorkProcess(data) {
        const response = await this.post('/api/MasterMaintenance/RegisterWorkProcess', data);
        return response;
    }

    async saveDevelopmentMethod(data) {
        const response = await this.post('/api/MasterMaintenance/RegisterDevelopmentMethod', data);
        return response;
    }

    async saveProjectRole(data) {
        const response = await this.post('/api/MasterMaintenance/RegisterProjectRole', data);
        return response;
    }

    async saveWorkType(data) {
        const response = await this.post('/api/MasterMaintenance/RegisterWorkType', data);
        return response;
    }

    async saveTechGroup(data) {
        const response = await this.post('/api/MasterMaintenance/RegisterTechGroup', data);
        return response;
    }
}

const masterMaintenanceService = new MasterMaintenanceService();
export default masterMaintenanceService;