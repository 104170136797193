import ServiceCommon from "./ServiceCommon";

class UserMaintenanceService extends ServiceCommon {
    async getUserMaintenanceInfo() {
        const response = await this.get('/api/UserMaintenance/getUserMaintenanceInfo');
        if (response.status === 200) {
            return await response.json();
        }
        return null;
    }

    async saveUserRole(data) {
        const response = await this.post('/api/UserMaintenance/saveUserRole', data);
        return response;
    }

    async saveAppRole(data) {
        const response = await this.post('/api/UserMaintenance/saveAppRole', data);
        return response;
    }
}

const userMaintenanceService = new UserMaintenanceService();
export default userMaintenanceService;