import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuAppBar from './Menu/MenuAppBar';
import MenuDrawer from './Menu/MenuDrawer';
import useStyles from './Menu/MenuStyles';

export default function Layout(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MenuAppBar open={open} onClick={handleDrawerOpen} userRole={props.userRole} />
            <MenuDrawer open={open} onClick={handleDrawerClose} userRole={props.userRole} />

            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div>
                    {props.children}
                </div>
            </main>
        </div>
    );
}
