import React, { Component } from 'react';
import { TechSummaryTable } from './Parts/TechSummaryTable';
import techSummaryService from '../../Service/TechSummaryService';
import { Loading } from '../Loading/Loading';

export class TechSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        const data = await techSummaryService.getTechSummary(this.props.userName);
        this.setState({
            loading: false,
            data
        });
    }

    render() {
        if (!this.state.loading && this.state.data.techSummaryList.length === 0) {
            return (<div>
                <h5>経歴情報の開発環境を登録すると表示されます</h5>
            </div>);
        }

        return (
            <div>
                <Loading loading={this.state.loading} />
                {!this.state.loading &&
                    <div>
                        <h4>主要技術情報</h4>
                        <TechSummaryTable
                            editable={false}
                            techGenreList={this.state.data.techGenreList}
                            techSummaryList={this.state.data.techSummaryList} />
                    </div>
                }
            </div>
        );
    }
}
