import React, { useState } from "react";
import { FormControlLabel, FormGroup, FormControl, Checkbox, Table, TableBody, TableRow, TableCell, TableContainer, Paper, makeStyles, Dialog, AppBar, IconButton, Typography, Toolbar, Button } from "@material-ui/core";
import { Fragment } from "react";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    table: {
        marginBottom: 0,
        '& .MuiTableCell-head': {
            fontWeight: "bold",
            backgroundColor: "#eaeff1",
        },

        '& .groupCell': {
            width: '150px',
        },
        '& .techCell': {
            width: '150px',
            background: 'aliceblue',
            fontWeight: 'bold'
        },
        '& .versionCell': {
        },
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export function TechCheckboxDialog(props) {
    const masterData = props.masterData;
    const classes = useStyles();

    const [checkedTechList, setCheckedTechList] = useState([]);

    // dialog open時イベント
    const handleOnEnter = () => {
        setCheckedTechList(props.techUsed.filter(t => t.techGenreId === props.techGenreId).slice());
    };

    // checkbox変更イベント
    const handleCheckboxChange = (techAndVersion, checked) => {
        let checkedTechListCopy = checkedTechList.slice();
        if (checked) {
            checkedTechListCopy.push(techAndVersion);
        } else {
            checkedTechListCopy = checkedTechListCopy.filter(tu => !(tu.techId === techAndVersion.techId && (tu.techVersionId === techAndVersion.techVersionId || (!tu.hasTechVersion && !techAndVersion.hasTechVersion))));
        }
        setCheckedTechList(checkedTechListCopy);
    };

    const checkedTechAndVersion = (techAndVersion) => {
        return Boolean(checkedTechList.filter(tu => tu.techId === techAndVersion.techId && (tu.techVersionId === techAndVersion.techVersionId || (!tu.hasTechVersion && !techAndVersion.hasTechVersion)))[0]);
    };

    const techGenre = masterData.techGenreList.filter(tg => tg.techGenreId === props.techGenreId)[0];
    const techGroupList = techGenre ? techGenre.techGroupList : [];
    return (
        <Fragment>
            <Dialog fullScreen open={props.open} onEnter={handleOnEnter} onClose={() => props.setTechGenreId(0)}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => props.setTechGenreId(0)} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {techGenre ? techGenre.techGenreName : ''}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => { props.techUsedChange(checkedTechList, props.techGenreId); props.setTechGenreId(0); }}>
                            選択
                        </Button>
                    </Toolbar>
                </AppBar>
                <TableContainer component={Paper}>
                    <Table className={classes.table}>
                        <TableBody>
                            {techGroupList.map((tg) =>
                                tg.techList.map((te, index) =>
                                    <TableRow key={te.techId}>
                                        {index === 0 && <TableCell className="groupCell" rowSpan={tg.techList.length} variant="head">{tg.techGroupName}</TableCell>}

                                        {te.hasTechVersion &&
                                            <TableCell className="techCell">
                                                {te.techName}
                                            </TableCell>
                                        }

                                        <TableCell className="versionCell" colSpan={te.hasTechVersion ? 1 : 2}>
                                            {te.techAndTechVersionList.map((techAndVersion, index) => {
                                                return (
                                                    <FormControl key={index}>
                                                        <FormGroup row>
                                                            <FormControlLabel
                                                                label={techAndVersion.description}
                                                                control={<Checkbox checked={checkedTechAndVersion(techAndVersion)} onChange={(e, checked) => handleCheckboxChange(techAndVersion, checked)} />}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                );
                                            })}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
        </Fragment>
    );
}
