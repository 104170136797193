import React, { Fragment } from "react";
import { makeStyles, Fab } from "@material-ui/core";
import { Add, ArrowBack } from "@material-ui/icons";
import { ViewProjectTable } from "./ViewProjectTable";

const useStyles = makeStyles({
    table: {
        minWidth: '1500px',
        maxHeight: "100px",
        '& .MuiTableCell-root': {
            border: "1px solid rgba(224, 224, 224, 1)",
            padding: "5px 10px 5px 10px"
        },
        '& .MuiTableCell-head': {
            fontWeight: "bold",
            backgroundColor: "#eaeff1",
        },
    },
    noCell: {
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            cursor: "pointer"
        }
    },
    fab: {
        position: 'fixed',
        bottom: "2rem",
        right: "2rem",
        '& .MuiFab-root': {
            marginLeft: "1em",
        },
    },
});

export function ViewProject(props) {
    const classes = useStyles();
    const table = props.data.projectList[0]
        ? <ViewProjectTable data={props.data} classes={classes} handleEditButton={(projectId) => props.handleEditButton(projectId)} userName={props.userName} />
        : <h5>登録されている経歴がありません</h5>;

    if (!props.loading && !props.data.isValid) {
        return (<div>
            <h5>基本情報を登録してください</h5>
        </div>);
    }

    const backButton = props.userName
        ? <Fab onClick={props.handleBackButton}><ArrowBack /></Fab>
        : <Fragment />
    return (
        <div>
            {!props.loading && table}
            <div className={classes.fab}>
                {backButton}
                <Fab color="primary" onClick={() => props.handleAddButton()}>
                    <Add />
                </Fab>
            </div>
        </div>
    );
}