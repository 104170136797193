import React from 'react';
import './Loading.css';

export function Loading(props) {
    if (props.loading) {
        return (
            <div id='lock-screen'>
                <div className="ball-pulse"><div></div><div></div><div></div></div>
            </div>
        );
    } else {
        return <div />;
    }
}