import ServiceCommon from "./ServiceCommon";


class AnalysisService extends ServiceCommon {
    async getAnalysisData() {
        const response = await this.get(`/api/Analysis/getAnalysisData`);
        if (response.status === 200) {
            return await response.json();
        }
        return null;
    }
}

const analysisService = new AnalysisService();
export default analysisService;