import React, { Fragment } from 'react';
import { Paper, InputBase, IconButton, makeStyles, Typography, Chip } from '@material-ui/core';
import { MoreHorizOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15
    },
    paper: {
        padding: '2px 4px',
        display: 'inline-flex',
        alignItems: 'center',
        width: 300,
        height: 48
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    chip: {
        margin: '3px 3px'
    }
}));

export function SearchBar(props) {
    const classes = useStyles();
    const params = props.params;
    const masterData = props.masterData;
    const techNameList = params.techIdList ? masterData.techList.filter(x => params.techIdList.includes(x.techId)).map(x => x.techName) : [];
    const developmentMethodNameList = params.developmentMethodIdList ? masterData.developmentMethodList.filter(x => params.developmentMethodIdList.includes(x.developmentMethodId)).map(x => x.developmentMethodName) : [];
    const projectRoleNameList = params.projectRoleIdList ? masterData.projectRoleList.filter(x => params.projectRoleIdList.includes(x.projectRoleId)).map(x => x.projectRoleName) : [];
    const workTypeNameList = params.workTypeIdList ? masterData.workTypeList.filter(x => params.workTypeIdList.includes(x.workTypeId)).map(x => x.workTypeName) : [];
    const workProcessNameList = params.workProcessIdList ? masterData.workProcessList.filter(x => params.workProcessIdList.includes(x.workProcessId)).map(x => x.workProcessName) : [];
    const nameList = techNameList.concat(developmentMethodNameList, projectRoleNameList, workTypeNameList, workProcessNameList);
    const chipDisplayNum = 6;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <InputBase
                    autoFocus
                    placeholder='名前を検索'
                    className={classes.input}
                    value={props.name}
                    onChange={(e) => props.onChange(e)}
                />
                <IconButton color="primary" onClick={() => props.onClick()} className={classes.iconButton} aria-label="directions">
                    <MoreHorizOutlined />
                </IconButton>
            </Paper>
            <div>
                {
                    nameList.slice(0, chipDisplayNum).map((name, i) => <Chip key={i} className={classes.chip} label={name} />)
                }
                {
                    nameList.length - chipDisplayNum > 0 && <Typography display='inline'>+{nameList.length - chipDisplayNum}</Typography>
                }
            </div>
        </div>
    );
}