import ServiceCommon from "./ServiceCommon";

class TechSummaryService extends ServiceCommon {
    async getTechSummary(userName) {
        const response = await this.get('/api/TechSummary/getTechSummary', { userName });
        if (response.status === 200) {
            return await response.json();
        }
        return null;
    }
}

const techSummaryService = new TechSummaryService();
export default techSummaryService;