import React, { Component } from "react";
import { ViewProject } from "./Parts/ViewProject";
import careerService from "../../Service/CareerService";
import { Loading } from "../Loading/Loading";
import { EditProject } from "./Parts/EditProject";
import { YesNoDialog } from "../Common/Dialogs";
import moment from 'moment';
import { Fragment } from "react";


export class Career extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                projectList: [],
                masterModel: {
                    workProcessList: [],
                    developmentMethodList: []
                }
            },
            loading: true,
            isEdit: false,
            saveDialogOpen: false,
            deleteDialogOpen: false
        }
        this.projectId = 0;
        this.pos = {
            x: 0,
            y: 0
        };
    }

    async componentDidMount() {
        const response = await careerService.getCareer(this.props.userName);
        const apiData = await response.json();
        this.setScroll(false);
        this.setState({
            loading: false,
            data: apiData
        });
    }

    /**
     * render前イベント
     * 
     * @param {any} nextProps
     * @param {any} nextState
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isEdit !== this.state.isEdit && nextState.isEdit) {
            this.saveScrollPos();
        }
        return true;
    }

    /**
     * render後イベント
     * 
     * @param {any} prevProps
     * @param {any} prevState
     * @param {any} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isEdit === this.state.isEdit) return;
        this.setScroll(this.state.isEdit);
    }

    /**
     * スクロール位置を保存する
     */
    saveScrollPos() {
        this.pos.x = window.scrollX;
        this.pos.y = window.scrollY;
    }

    /**
     * スクロール位置を設定する
     * 
     * @param {any} isEdit
     */
    setScroll(isEdit) {
        if (isEdit) {
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(this.pos.x, this.pos.y);
        }
    }

    handleAddButton() {
        this.setState({ isEdit: true });
    }

    handleEditButton(projectId) {
        this.projectId = projectId;
        this.setState({ isEdit: true });
    }

    handleSaveButton(data, developmentMethod, projectRole, workType, workProcessIdList, techUsed) {
        if (!(projectRole && workType) || moment(data.startMonth + "-01").isAfter(moment(data.endMonth + "-01"))) {
            return;
        }
        this.sendData = {
            projectId: this.projectId,
            startMonth: data.startMonth + "-01",
            endMonth: data.endMonth + "-01",
            wholeMemberCount: parseInt(data.wholeMemberCount),
            teamMemberCount: parseInt(data.teamMemberCount),
            projectOverview: data.projectOverview,
            workContent: data.workContent,
            developmentMethodId: developmentMethod ? developmentMethod : null,
            projectRoleId: projectRole,
            workTypeId: workType,
            workProcessIdList: workProcessIdList,
            techUsed: techUsed,
            userName: this.props.userName
        }
        this.setState({ saveDialogOpen: true });
    }

    async handleSaveYesButton() {
        this.setState({
            saveDialogOpen: false,
            loading: true
        });
        await careerService.saveProject(this.sendData);
        const getResponse = await careerService.getCareer(this.props.userName);
        const apiData = await getResponse.json();
        this.projectId = 0;
        this.setState({
            loading: false,
            data: apiData,
            isEdit: false
        });
    }

    handleSaveNoButton() {
        this.setState({ saveDialogOpen: false });
    }

    async handleDeleteYesButton() {
        this.setState({
            deleteDialogOpen: false,
            loading: true
        });
        await careerService.deleteProject({ projectId: this.projectId, userName: this.props.userName });
        const getResponse = await careerService.getCareer(this.props.userName);
        const apiData = await getResponse.json();
        this.projectId = 0;
        this.setState({
            loading: false,
            data: apiData,
            isEdit: false
        });
    }

    handleDeleteNoButton() {
        this.setState({
            deleteDialogOpen: false
        });
    }

    handleCancelButton() {
        this.projectId = 0;
        this.setState({ isEdit: false });
    }

    handleDeleteButton() {
        this.setState({ deleteDialogOpen: true });
    }

    render() {
        const element = this.state.isEdit
            ? <EditProject
                masterData={this.state.data.masterModel}
                project={this.projectId === 0 ?
                    {
                        developmentMethodId: "",
                        endMonth: "",
                        projectId: 0,
                        projectOverview: "",
                        projectRoleId: "",
                        startMonth: "",
                        teamMemberCount: 0,
                        techUsed: [],
                        wholeMemberCount: 0,
                        workContent: "",
                        workProcessIdList: [],
                        workTypeId: "",
                    }
                    : this.state.data.projectList.filter(p => p.projectId === this.projectId)[0]}
                handleSaveButton={(data, developmentMethod, projectRole, workType, workProcessIdList, techUsed) => this.handleSaveButton(data, developmentMethod, projectRole, workType, workProcessIdList, techUsed)}
                handleCancelButton={() => this.handleCancelButton()}
                handleDeleteButton={() => this.handleDeleteButton()}
            />
            : <ViewProject
                loading={this.state.loading}
                data={this.state.data}
                handleAddButton={() => this.handleAddButton()}
                handleEditButton={(projectId) => this.handleEditButton(projectId)}
                handleBackButton={() => this.props.handleBackButton()}
                userName={this.props.userName}
            />;

        const userFullName = this.props.fullName ? <h3>{this.props.fullName}</h3> : <Fragment />;
        return (
            <div>
                <Loading loading={this.state.loading} />
                {userFullName}
                <YesNoDialog
                    open={this.state.saveDialogOpen}
                    message='保存しますか？'
                    onClickYes={() => this.handleSaveYesButton()}
                    onClickNo={() => this.handleSaveNoButton()} />
                <YesNoDialog
                    open={this.state.deleteDialogOpen}
                    message='削除しますか？'
                    onClickYes={() => this.handleDeleteYesButton()}
                    onClickNo={() => this.handleDeleteNoButton()} />

                {element}
            </div>
        );
    }
}