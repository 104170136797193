class Utility {
    /**
     * 月数を年数と月数に分割し文字列で返す
     * @param {any} month
     */
    convertFromMonthToYearMonth(month) {
        const strYear = Math.floor(month / 12) ? Math.floor(month / 12) + "年" : "";
        const strMonth = month % 12 ? month % 12 + "ヵ月" : "";
        return strYear + strMonth;
    }

    /**
     * 半角を1文字、全角を2文字として文字数をカウントする
     * @param {any} str
     */
    conuntHankakuZenkaku(str) {
        let result = 0;
        for (let i = 0; i < str.length; i++) {
            var chr = str.charCodeAt(i);
            if ((chr >= 0x00 && chr < 0x81) ||
                (chr === 0xf8f0) ||
                (chr >= 0xff61 && chr < 0xffa0) ||
                (chr >= 0xf8f1 && chr < 0xf8f4)) {
                //半角文字の場合は1を加算
                result += 1;
            } else {
                //それ以外の文字の場合は2を加算
                result += 2;
            }
        }
        return result;
    }

    createIndex() {
        return new Index();
    }
}
class Index {
    constructor() {
        this.index = 0;
    }

    nextIndex() {
        this.index++;
        return this.index;
    }

    nextIndexString() {
        return `*${this.nextIndex()}`;
    }
}
const utility = new Utility();
export default utility;