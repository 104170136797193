import React, { Fragment } from 'react';
import moment from 'moment';
import { TableRow, TableCell, IconButton, Collapse, Box, Typography, Table, TableBody, Tooltip, makeStyles } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown, Work, Person } from '@material-ui/icons';
import { useState } from 'react';
import utility from '../../../Utility';
import { EtcMenu } from './EtcMenu';

const useStyles = makeStyles({
    dataRow: {
        '&:hover': {
            backgroundColor: "#e3f2fd"
        }
    },
});

export function UsersTableRow(props) {
    const classes = useStyles();
    const [changeCount, setChangeCount] = useState(0);
    const userProfile = props.user.userProfileModel;
    const onClick = async () => {
        await props.getUserSkill(props.user.userName);
        setChangeCount(changeCount + 1);
    };

    return (
        <Fragment>
            <TableRow className={classes.dataRow}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={onClick}>
                        {props.user.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{`${userProfile.familyName} ${userProfile.firstName}`}</TableCell>
                <TableCell>{userProfile.gender === 1 ? "男性" : "女性"}</TableCell>
                <TableCell>{`${moment().diff(moment(userProfile.birthday, 'YYYY-MM-DD'), 'years')}歳`}</TableCell>
                <TableCell>{utility.convertFromMonthToYearMonth(userProfile.experienceMonth)}</TableCell>
                <TableCell>{userProfile.nearestStation}</TableCell>
                <TableCell>{props.user.lastUpdateDateTime ? moment(props.user.lastUpdateDateTime).format("YYYY-MM-DD HH:mm:ss") : ''}</TableCell>
                <TableCell style={{ width: '10px' }}>
                    <Tooltip title="基本情報">
                        <IconButton onClick={() => props.handleProfileButton(props.user.userName)}><Person /></IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell style={{ width: '10px' }}>
                    <Tooltip title="経歴情報">
                        <IconButton onClick={() => props.handleDetailButton(props.user.userName)}><Work /></IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell style={{ width: '10px' }}>
                    <EtcMenu userName={props.user.userName} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={props.user.open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                技術情報
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {props.user.skillList && props.user.skillList.map((skill, index) => (
                                        <TableRow key={index}>
                                            <TableCell variant="head" style={{ width: '120px' }}>
                                                {props.masterData.techGenreList.filter(g => g.techGenreId === skill.techGenreId)[0] &&
                                                    props.masterData.techGenreList.filter(g => g.techGenreId === skill.techGenreId)[0].techGenreName
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ width: '300px' }}>
                                                {skill.techName}
                                            </TableCell>
                                            <TableCell>{utility.convertFromMonthToYearMonth(skill.experienceMonth)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}