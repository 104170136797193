import React, { Component, Fragment } from 'react';
import moment from 'moment';
import searchService from '../../Service/SearchService';
import { Loading } from '../Loading/Loading';
import { UsersTable } from './Parts/UsersTable';
import { Career } from '../Career/Career';
import { SearchBar } from './Parts/SearchBar';
import { createSearchURLBase64, URLSearchParamsBase64 } from '../../Service/ServiceCommon'
import { Profile } from '../Profile/Profile';
import { Button } from '@material-ui/core';
import { SearchDialog } from './Parts/SearchDialog';

export class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                masterData: { techList: [], techGenreList: [], developmentMethodList: [], projectRoleList: [], workTypeList: [], workProcessList: [] },
                users: [],
            },
            loading: true,
            tech: {
                techId: 0
            },
            searchName: '',
            dialogOpen: false,
            backPram: ''
        }
    }

    async componentDidMount() {
        this.getUsers(this.props.location.search);

        this.unregisterHistoryCallback = this.props.history.listen((location) => {
            this.getUsers(location.search);
        });
    }

    componentWillUnmount() {
        this.unregisterHistoryCallback();
    }

    /**
     * ユーザー取得
     * @param {any} search
     */
    async getUsers(search) {
        this.setState({ loading: true });
        const params = new URLSearchParamsBase64(search);
        const response = await searchService.getMaster(params.techIdList, params.developmentMethodIdList, params.projectRoleIdList, params.workTypeIdList, params.workProcessIdList);
        const apiData = await response.json();
        this.setState({
            data: apiData,
            loading: false,
            dialogOpen: false
        });
    }

    /**
     * リロード時イベント
     * */
    async reload() {
        this.getUsers(this.props.location.search);
    }

    onTechChange(value) {
        this.setState({ tech: value });
    }

    handleDetailButton(userName) {
        const userData = this.state.data.users.filter(u => u.userName === userName)[0].userProfileModel;
        const fullName = `${userData.familyName}　${userData.firstName}`;
        const url = createSearchURLBase64('', { display: 'career', userName, fullName });
        this.props.history.push(url);
        this.setState({ backPram: this.props.location.search });
    }

    handleProfileButton(userName) {
        const url = createSearchURLBase64('', { display: 'profile', userName })
        this.props.history.push(url);
        this.setState({ backPram: this.props.location.search });
    }

    handleBackButton() {
        this.props.history.push(`/search${this.state.backPram ? this.state.backPram : ''}`);
    }

    handleNameChange(e) {
        this.setState({ searchName: e.target.value });
    }

    /**
     * 検索ボタン押下時イベント
     * @param {any} techList
     * @param {any} developmentMethodIdList
     * @param {any} projectRoleIdList
     * @param {any} workTypeIdList
     * @param {any} workProcessIdList
     */
    handleSearchButton(techList, developmentMethodIdList, projectRoleIdList, workTypeIdList, workProcessIdList) {
        const techIdList = techList.map(x => (x.techId));
        const url = createSearchURLBase64('', { techIdList, developmentMethodIdList, projectRoleIdList, workTypeIdList, workProcessIdList });
        this.props.history.push(url);
    }

    handleSearchClearButton() {
        this.props.history.push('/search');
    }

    render() {
        const params = new URLSearchParamsBase64(this.props.location.search);
        const element = params.display === 'career'
            ? <Career
                userName={params.userName}
                fullName={params.fullName}
                handleBackButton={() => this.handleBackButton()}
            />
            : params.display === 'profile'
                ? <Profile
                    userName={params.userName}
                    handleBackButton={() => this.handleBackButton()}
                />
                : <Fragment>
                    <SearchBar name={this.state.searchName}
                        onChange={(e) => this.handleNameChange(e)}
                        onClick={() => this.setState({ dialogOpen: true })}
                        params={params}
                        masterData={this.state.data.masterData}
                    />
                    <SearchDialog
                        open={this.state.dialogOpen}
                        handleSearchButton={(techList, developmentMethodIdList, projectRoleIdList, workTypeIdList, workProcessIdList) => this.handleSearchButton(techList, developmentMethodIdList, projectRoleIdList, workTypeIdList, workProcessIdList)}
                        handleSearchClearButton={() => this.handleSearchClearButton()}
                        handleClose={() => this.setState({ dialogOpen: false })}
                        masterData={this.state.data.masterData}
                        params={params}
                    />
                    <UsersTable
                        users={this.state.data.users}
                        masterData={this.state.data.masterData}
                        searchName={this.state.searchName}
                        handleReload={() => this.reload()}
                        handleDetailButton={(userName) => this.handleDetailButton(userName)}
                        handleProfileButton={(userName) => this.handleProfileButton(userName)}
                    />
                </Fragment>;
        const elementOrLoading = this.state.loading ? <Loading loading={this.state.loading} /> : element;

        return (
            <div>
                {elementOrLoading}
            </div>
        );
    }
}
